/* bootstrap 4.3.0 */

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}


/* color */

.bg-dark-blue {
  background-color: #0068bf;
}

.bg-secondary-gradient {
  background: #0CBAB0 !important;
  background: -webkit-linear-gradient(legacy-direction(-45deg), #0CBAB0, #38DE9E) !important;
  background: linear-gradient(-45deg, #0CBAB0, #38DE9E) !important;
}

.bg-grays {
  background: #b1afaf1c;
}

.bg-yellow {
  background: #FFFFCC;
}

.border-green {
  border-color: #19a975 !important;
}

/* login / reset pwd */
/*     	Login  v2   */

.wrapper {
  min-height: 100vh;
  position: relative;
  top: 0;
  height: 100vh;
}

.login .wrapper.wrapper-login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: unset;
  padding: 15px;
}

.login .wrapper.wrapper-login .container-login {
  padding: 60px 25px 15px;
  border-radius: 5px;
  width: 400px;
}

.login .wrapper.wrapper-login .container-signup {
  padding: 60px 25px;
  border-radius: 5px;
  width: 400px;
}

.login .wrapper.wrapper-login .container-login:not(.container-transparent), .login .wrapper.wrapper-login .container-signup:not(.container-transparent) {
  background: #ffffff;
  -webkit-box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  -moz-box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  border: 1px solid #ebecec;
}

.login .wrapper.wrapper-login .container-login h3, .login .wrapper.wrapper-login .container-signup h3 {
  font-size: 19px;
  font-weight: 600;
  margin-bottom: 25px;
}

.login .wrapper.wrapper-login .container-login .form-sub, .login .wrapper.wrapper-login .container-signup .form-sub {
  align-items: center;
  justify-content: space-between;
  padding: 8px 10px;
}

.login .wrapper.wrapper-login .container-login .btn-login, .login .wrapper.wrapper-login .container-signup .btn-login {
  padding: 15px 0;
  width: 135px;
}

.login .wrapper.wrapper-login .container-login .form-action, .login .wrapper.wrapper-login .container-signup .form-action {
  text-align: center;
  padding: 25px 10px 0;
}

.login .wrapper.wrapper-login .container-login .form-action-d-flex, .login .wrapper.wrapper-login .container-signup .form-action-d-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.login .wrapper.wrapper-login .container-login .login-account, .login .wrapper.wrapper-login .container-signup .login-account {
  padding-top: 10px;
  text-align: center;
}

.login .wrapper.wrapper-login .container-signup .form-action {
  display: flex;
  justify-content: center;
}

.login .wrapper.wrapper-login-full {
  justify-content: unset;
  align-items: unset;
  padding: 0 !important;
}

.login .login-aside {
  padding: 25px;
}

.login .login-aside .title {
  font-size: 36px;
}

.login .login-aside .subtitle {
  font-size: 18px;
}

.login .show-password {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  cursor: pointer;
}

.login .custom-control-label {
  white-space: nowrap;
}

span#siteseal {
  position: absolute;
  bottom: 3.5%;
}

.mess-img {
  height: 3em;
  margin-top: 18px;
}

@media screen and (max-width: 576px) {
  .form-action-d-flex {
    flex-direction: column;
    align-items: start !important;
  }

  .login .wrapper-login-full {
    flex-direction: column;
  }

  .login .login-aside {
    width: 100% !important;
  }

  .login .login-aside .title {
    font-size: 24px;
  }

  .login .login-aside .subtitle {
    font-size: 16px;
  }
}

@media screen and (max-width: 399px) {
  .wrapper-login {
    padding: 15px !important;
  }

  .container-login {
    width: 100% !important;
    padding: 45px 15px 15px 15px !important;
  }
}

/* */
.aside-menu-fixed.aside-menu-hidden .main {
  overflow: hidden;
}

/* sidebar v2 */

/* Sidebar style 2 */

.sidebar .nav > .nav-item.active > a:before, .sidebar[data-background-color="white"] .nav > .nav-item.active > a:before, .sidebar .nav > .nav-item.active:hover > a:before, .sidebar[data-background-color="white"] .nav > .nav-item.active:hover > a:before, .sidebar .nav > .nav-item a[data-toggle=collapse][aria-expanded=true]:before, .sidebar[data-background-color="white"] .nav > .nav-item a[data-toggle=collapse][aria-expanded=true]:before {
  opacity: 1 !important;
  position: absolute;
  z-index: 1;
  width: 3px;
  height: 100%;
  content: '';
  left: 0;
  top: 0;
}

.sidebar, .sidebar[data-background-color="white"] {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 250px;
  margin-top: 45px;
  display: block;
  z-index: 1000;
  color: #ffffff;
  font-weight: 200;
  background: #ffffff;
  -webkit-box-shadow: 4px 4px 10px rgba(69, 65, 78, 0.06);
  -moz-box-shadow: 4px 4px 10px rgba(69, 65, 78, 0.06);
  box-shadow: 4px 4px 10px rgba(69, 65, 78, 0.06);
  transition: all .3s;
}

.sidebar.full-height, .sidebar[data-background-color="white"].full-height {
  margin-top: 0;
}

.sidebar .user, .sidebar[data-background-color="white"] .user {
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  border-top: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;
  display: block;
  margin-left: 15px;
  margin-right: 15px;
}

.sidebar .user .info a, .sidebar[data-background-color="white"] .user .info a {
  white-space: nowrap;
  display: block;
  position: relative;
}

.sidebar .user .info a:hover, .sidebar .user .info a:focus, .sidebar[data-background-color="white"] .user .info a:hover, .sidebar[data-background-color="white"] .user .info a:focus {
  text-decoration: none;
}

.sidebar .user .info a > span, .sidebar[data-background-color="white"] .user .info a > span {
  font-size: 14px;
  font-weight: 400;
  color: #777;
  display: flex;
  flex-direction: column;
}

.sidebar .user .info a > span .user-level, .sidebar[data-background-color="white"] .user .info a > span .user-level {
  color: #555;
  font-weight: 600;
  font-size: 12px;
  margin-top: 5px;
}

.sidebar .user .info a .link-collapse, .sidebar[data-background-color="white"] .user .info a .link-collapse {
  padding: 7px 0;
}

.sidebar .user .info .caret, .sidebar[data-background-color="white"] .user .info .caret {
  position: absolute;
  top: 17px;
  right: 0px;
  border-top-color: #777;
}

.sidebar .sidebar-wrapper, .sidebar[data-background-color="white"] .sidebar-wrapper {
  position: relative;
  max-height: calc(100vh - 75px);
  min-height: 100%;
  overflow: unset;
  width: 100%;
  z-index: 4;
  padding-bottom: 100px;
  transition: all .3s;
}

.sidebar .sidebar-wrapper .sidebar-content, .sidebar[data-background-color="white"] .sidebar-wrapper .sidebar-content {
  padding-top: 0px;
  padding-bottom: 55px;
}

.sidebar .sidebar-wrapper .scroll-element.scroll-y, .sidebar[data-background-color="white"] .sidebar-wrapper .scroll-element.scroll-y {
  top: 5px !important;
}

.sidebar .nav, .sidebar[data-background-color="white"] .nav {
  display: block;
  float: none;
  margin-top: 20px;
}

.sidebar .nav .nav-section, .sidebar[data-background-color="white"] .nav .nav-section {
  margin: 15px 0 0 0;
}

.sidebar .nav .nav-section .sidebar-mini-icon, .sidebar[data-background-color="white"] .nav .nav-section .sidebar-mini-icon {
  text-align: center;
  font-size: 15px;
  color: #909093;
  display: none;
}

.sidebar .nav .nav-section .text-section, .sidebar[data-background-color="white"] .nav .nav-section .text-section {
  padding: 2px 30px;
  font-size: 12px;
  color: #727275;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-bottom: 12px;
  margin-top: 20px;
}

.sidebar .nav > .nav-item, .sidebar[data-background-color="white"] .nav > .nav-item {
  display: list-item;
}

.sidebar .nav > .nav-item.active > a, .sidebar[data-background-color="white"] .nav > .nav-item.active > a {
  color: #2A2F5B !important;
}

.sidebar .nav > .nav-item.active > a:before, .sidebar[data-background-color="white"] .nav > .nav-item.active > a:before {
  background: #1d7af3;
}

.sidebar .nav > .nav-item.active > a p, .sidebar[data-background-color="white"] .nav > .nav-item.active > a p {
  color: #2A2F5B !important;
  font-weight: 600;
}

.sidebar .nav > .nav-item.active:hover > a:before, .sidebar[data-background-color="white"] .nav > .nav-item.active:hover > a:before {
  background: #1d7af3;
}

.sidebar .nav > .nav-item.active a i, .sidebar[data-background-color="white"] .nav > .nav-item.active a i {
  color: #4d7cfe;
}

.sidebar .nav > .nav-item.submenu, .sidebar[data-background-color="white"] .nav > .nav-item.submenu {
  background: rgba(0, 0, 0, 0.03);
}

.sidebar .nav > .nav-item.submenu > li > a i, .sidebar[data-background-color="white"] .nav > .nav-item.submenu > li > a i {
  color: rgba(23, 125, 255, 0.76);
}

.sidebar .nav > .nav-item > a:hover, .sidebar .nav > .nav-item a:focus, .sidebar[data-background-color="white"] .nav > .nav-item > a:hover, .sidebar[data-background-color="white"] .nav > .nav-item a:focus {
  background: rgba(0, 0, 0, 0.03);
}

.sidebar .nav > .nav-item a, .sidebar[data-background-color="white"] .nav > .nav-item a {
  display: flex;
  align-items: center;
  color: #575962;
  padding: 6px 25px;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  position: relative;
  margin-bottom: 3px;
}

.sidebar .nav > .nav-item a:hover, .sidebar .nav > .nav-item a:focus, .sidebar[data-background-color="white"] .nav > .nav-item a:hover, .sidebar[data-background-color="white"] .nav > .nav-item a:focus {
  text-decoration: none;
}

.sidebar .nav > .nav-item a:hover p, .sidebar .nav > .nav-item a:focus p, .sidebar[data-background-color="white"] .nav > .nav-item a:hover p, .sidebar[data-background-color="white"] .nav > .nav-item a:focus p {
  color: #575962 !important;
  font-weight: 600;
}

.sidebar .nav > .nav-item a:hover i, .sidebar .nav > .nav-item a:focus i, .sidebar[data-background-color="white"] .nav > .nav-item a:hover i, .sidebar[data-background-color="white"] .nav > .nav-item a:focus i {
  color: #4d7cfe !important;
}

.sidebar .nav > .nav-item a .letter-icon, .sidebar[data-background-color="white"] .nav > .nav-item a .letter-icon {
  color: #a1a2a6;
  margin-right: 15px;
  width: 25px;
  text-align: center;
  vertical-align: middle;
  float: left;
  font-size: 20px;
  font-weight: 200;
}

.sidebar .nav > .nav-item a i, .sidebar[data-background-color="white"] .nav > .nav-item a i {
  color: #8d9498;
  margin-right: 15px;
  width: 25px;
  text-align: center;
  vertical-align: middle;
  float: left;
  font-size: 18px;
  line-height: 30px;
}

.sidebar .nav > .nav-item a i[class^="flaticon-"], .sidebar[data-background-color="white"] .nav > .nav-item a i[class^="flaticon-"] {
  font-size: 20px;
}

.sidebar .nav > .nav-item a p, .sidebar[data-background-color="white"] .nav > .nav-item a p {
  font-size: 14px;
  margin-bottom: 0px;
  margin-right: 5px;
  white-space: nowrap;
  color: #8d9498;
}

.sidebar .nav > .nav-item a .caret, .sidebar[data-background-color="white"] .nav > .nav-item a .caret {
  margin-left: auto;
  margin-right: 10px;
  transition: all .5s;
  color: #8d9498;
}

.sidebar .nav > .nav-item a[data-toggle=collapse][aria-expanded=true], .sidebar[data-background-color="white"] .nav > .nav-item a[data-toggle=collapse][aria-expanded=true] {
  background: transparent;
}

.sidebar .nav > .nav-item a[data-toggle=collapse][aria-expanded=true] p, .sidebar[data-background-color="white"] .nav > .nav-item a[data-toggle=collapse][aria-expanded=true] p {
  color: #575962;
}

.sidebar .nav > .nav-item a[data-toggle=collapse][aria-expanded=true] i, .sidebar[data-background-color="white"] .nav > .nav-item a[data-toggle=collapse][aria-expanded=true] i {
  color: #4d7cfe;
}

.sidebar .nav > .nav-item a[data-toggle=collapse][aria-expanded=true] .caret, .sidebar[data-background-color="white"] .nav > .nav-item a[data-toggle=collapse][aria-expanded=true] .caret {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.sidebar .nav > .nav-item a[data-toggle=collapse][aria-expanded=true]:before, .sidebar[data-background-color="white"] .nav > .nav-item a[data-toggle=collapse][aria-expanded=true]:before {
  background: #1d7af3;
}

.sidebar .nav.nav-primary > .nav-item a:hover i, .sidebar .nav.nav-primary > .nav-item a:focus i, .sidebar .nav.nav-primary > .nav-item a[data-toggle=collapse][aria-expanded=true] i, .sidebar[data-background-color="white"] .nav.nav-primary > .nav-item a:hover i, .sidebar[data-background-color="white"] .nav.nav-primary > .nav-item a:focus i, .sidebar[data-background-color="white"] .nav.nav-primary > .nav-item a[data-toggle=collapse][aria-expanded=true] i {
  color: #0068bf !important;
}

.sidebar .nav.nav-primary > .nav-item a[data-toggle=collapse][aria-expanded=true]:before, .sidebar[data-background-color="white"] .nav.nav-primary > .nav-item a[data-toggle=collapse][aria-expanded=true]:before {
  background: #0068bf !important;
}

.sidebar .nav.nav-primary > .nav-item.active a:before, .sidebar[data-background-color="white"] .nav.nav-primary > .nav-item.active a:before {
  background: #0068bf !important;
}

.sidebar .nav.nav-primary > .nav-item.active a i, .sidebar[data-background-color="white"] .nav.nav-primary > .nav-item.active a i {
  color: #0068bf !important;
}

.sidebar .nav.nav-secondary > .nav-item a:hover i, .sidebar .nav.nav-secondary > .nav-item a:focus i, .sidebar .nav.nav-secondary > .nav-item a[data-toggle=collapse][aria-expanded=true] i, .sidebar[data-background-color="white"] .nav.nav-secondary > .nav-item a:hover i, .sidebar[data-background-color="white"] .nav.nav-secondary > .nav-item a:focus i, .sidebar[data-background-color="white"] .nav.nav-secondary > .nav-item a[data-toggle=collapse][aria-expanded=true] i {
  color: #6861CE !important;
}

.sidebar .nav.nav-secondary > .nav-item a[data-toggle=collapse][aria-expanded=true]:before, .sidebar[data-background-color="white"] .nav.nav-secondary > .nav-item a[data-toggle=collapse][aria-expanded=true]:before {
  background: #6861CE !important;
}

.sidebar .nav.nav-secondary > .nav-item.active a:before, .sidebar[data-background-color="white"] .nav.nav-secondary > .nav-item.active a:before {
  background: #6861CE !important;
}

.sidebar .nav.nav-secondary > .nav-item.active a i, .sidebar[data-background-color="white"] .nav.nav-secondary > .nav-item.active a i {
  color: #6861CE !important;
}

.sidebar .nav.nav-info > .nav-item a:hover i, .sidebar .nav.nav-info > .nav-item a:focus i, .sidebar .nav.nav-info > .nav-item a[data-toggle=collapse][aria-expanded=true] i, .sidebar[data-background-color="white"] .nav.nav-info > .nav-item a:hover i, .sidebar[data-background-color="white"] .nav.nav-info > .nav-item a:focus i, .sidebar[data-background-color="white"] .nav.nav-info > .nav-item a[data-toggle=collapse][aria-expanded=true] i {
  color: #48ABF7 !important;
}

.sidebar .nav.nav-info > .nav-item a[data-toggle=collapse][aria-expanded=true]:before, .sidebar[data-background-color="white"] .nav.nav-info > .nav-item a[data-toggle=collapse][aria-expanded=true]:before {
  background: #48ABF7 !important;
}

.sidebar .nav.nav-info > .nav-item.active a:before, .sidebar[data-background-color="white"] .nav.nav-info > .nav-item.active a:before {
  background: #48ABF7 !important;
}

.sidebar .nav.nav-info > .nav-item.active a i, .sidebar[data-background-color="white"] .nav.nav-info > .nav-item.active a i {
  color: #48ABF7 !important;
}

.sidebar .nav.nav-success > .nav-item a:hover i, .sidebar .nav.nav-success > .nav-item a:focus i, .sidebar .nav.nav-success > .nav-item a[data-toggle=collapse][aria-expanded=true] i, .sidebar[data-background-color="white"] .nav.nav-success > .nav-item a:hover i, .sidebar[data-background-color="white"] .nav.nav-success > .nav-item a:focus i, .sidebar[data-background-color="white"] .nav.nav-success > .nav-item a[data-toggle=collapse][aria-expanded=true] i {
  color: #31CE36 !important;
}

.sidebar .nav.nav-success > .nav-item a[data-toggle=collapse][aria-expanded=true]:before, .sidebar[data-background-color="white"] .nav.nav-success > .nav-item a[data-toggle=collapse][aria-expanded=true]:before {
  background: #31CE36 !important;
}

.sidebar .nav.nav-success > .nav-item.active a:before, .sidebar[data-background-color="white"] .nav.nav-success > .nav-item.active a:before {
  background: #31CE36 !important;
}

.sidebar .nav.nav-success > .nav-item.active a i, .sidebar[data-background-color="white"] .nav.nav-success > .nav-item.active a i {
  color: #31CE36 !important;
}

.sidebar .nav.nav-warning > .nav-item a:hover i, .sidebar .nav.nav-warning > .nav-item a:focus i, .sidebar .nav.nav-warning > .nav-item a[data-toggle=collapse][aria-expanded=true] i, .sidebar[data-background-color="white"] .nav.nav-warning > .nav-item a:hover i, .sidebar[data-background-color="white"] .nav.nav-warning > .nav-item a:focus i, .sidebar[data-background-color="white"] .nav.nav-warning > .nav-item a[data-toggle=collapse][aria-expanded=true] i {
  color: #FFAD46 !important;
}

.sidebar .nav.nav-warning > .nav-item a[data-toggle=collapse][aria-expanded=true]:before, .sidebar[data-background-color="white"] .nav.nav-warning > .nav-item a[data-toggle=collapse][aria-expanded=true]:before {
  background: #FFAD46 !important;
}

.sidebar .nav.nav-warning > .nav-item.active a:before, .sidebar[data-background-color="white"] .nav.nav-warning > .nav-item.active a:before {
  background: #FFAD46 !important;
}

.sidebar .nav.nav-warning > .nav-item.active a i, .sidebar[data-background-color="white"] .nav.nav-warning > .nav-item.active a i {
  color: #FFAD46 !important;
}

.sidebar .nav.nav-danger > .nav-item a:hover i, .sidebar .nav.nav-danger > .nav-item a:focus i, .sidebar .nav.nav-danger > .nav-item a[data-toggle=collapse][aria-expanded=true] i, .sidebar[data-background-color="white"] .nav.nav-danger > .nav-item a:hover i, .sidebar[data-background-color="white"] .nav.nav-danger > .nav-item a:focus i, .sidebar[data-background-color="white"] .nav.nav-danger > .nav-item a[data-toggle=collapse][aria-expanded=true] i {
  color: #F25961 !important;
}

.sidebar .nav.nav-danger > .nav-item a[data-toggle=collapse][aria-expanded=true]:before, .sidebar[data-background-color="white"] .nav.nav-danger > .nav-item a[data-toggle=collapse][aria-expanded=true]:before {
  background: #F25961 !important;
}

.sidebar .nav.nav-danger > .nav-item.active a:before, .sidebar[data-background-color="white"] .nav.nav-danger > .nav-item.active a:before {
  background: #F25961 !important;
}

.sidebar .nav.nav-danger > .nav-item.active a i, .sidebar[data-background-color="white"] .nav.nav-danger > .nav-item.active a i {
  color: #F25961 !important;
}

.sidebar .nav-collapse, .sidebar[data-background-color="white"] .nav-collapse {
  margin-top: 0px;
  /*margin-bottom: 15px;*/
  padding-bottom: 15px;
  padding-top: 10px;

  transition: height .3s ease-in-out;
}

.sidebar .nav-collapse li.active > a, .sidebar[data-background-color="white"] .nav-collapse li.active > a {
  font-weight: 600;
}

.sidebar .nav-collapse li a:before, .sidebar .nav-collapse li a:hover:before, .sidebar[data-background-color="white"] .nav-collapse li a:before, .sidebar[data-background-color="white"] .nav-collapse li a:hover:before {
  opacity: 0 !important;
}

.sidebar .nav-collapse li a, .sidebar[data-background-color="white"] .nav-collapse li a {
  margin-bottom: 3px !important;
  padding: 10px 25px !important;
}

.sidebar .nav-collapse li a .sub-item, .sidebar[data-background-color="white"] .nav-collapse li a .sub-item {
  font-size: 14px;
  position: relative;
  margin-left: 25px;
  opacity: .85;
}

.sidebar .nav-collapse li a .sub-item:before, .sidebar[data-background-color="white"] .nav-collapse li a .sub-item:before {
  content: '';
  height: 4px;
  width: 4px;
  background: rgba(131, 132, 138, 0.89);
  position: absolute;
  left: -15px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 100%;
}

.sidebar .nav-collapse li a:hover .sub-item, .sidebar[data-background-color="white"] .nav-collapse li a:hover .sub-item {
  opacity: 1;
}

.sidebar .nav-collapse li a .sidebar-mini-icon, .sidebar[data-background-color="white"] .nav-collapse li a .sidebar-mini-icon {
  font-size: 18px;
  color: #C3C5CA;
  margin-right: 15px;
  width: 25px;
  text-align: center;
  vertical-align: middle;
  float: left;
  font-weight: 300 !important;
}

.sidebar .nav-collapse.subnav, .sidebar[data-background-color="white"] .nav-collapse.subnav {
  padding-bottom: 10px;
  margin-bottom: 0px;
}

.sidebar .nav-collapse.subnav li a, .sidebar[data-background-color="white"] .nav-collapse.subnav li a {
  padding-left: 40px !important;
}

/**/

.sidebar.sidebar-style-2 .nav .nav-item {
  padding: 0 15px;
}

.sidebar.sidebar-style-2 .nav .nav-item a {
  padding: 8px 10px;
  border-radius: 5px;
}

.sidebar.sidebar-style-2 .nav .nav-item a:hover, .sidebar.sidebar-style-2 .nav .nav-item a:focus, .sidebar.sidebar-style-2 .nav .nav-item a[data-toggle=collapse][aria-expanded=true] {
  background: rgba(199, 199, 199, 0.2);
}

.sidebar.sidebar-style-2 .nav .nav-item a:hover p, .sidebar.sidebar-style-2 .nav .nav-item a:hover i, .sidebar.sidebar-style-2 .nav .nav-item a:focus p, .sidebar.sidebar-style-2 .nav .nav-item a:focus i, .sidebar.sidebar-style-2 .nav .nav-item a[data-toggle=collapse][aria-expanded=true] p, .sidebar.sidebar-style-2 .nav .nav-item a[data-toggle=collapse][aria-expanded=true] i {
  color: #575962 !important;
}

.sidebar.sidebar-style-2 .nav .nav-item.active a:before {
  background: transparent;
}

.sidebar.sidebar-style-2 .nav .nav-item .active a {
  background: rgba(142, 128, 128, 0.2);
}

.sidebar.sidebar-style-2 .nav .nav-item .active a p, .sidebar.sidebar-style-2 .nav .nav-item .active a i {
  color: #575962 !important;
}

.sidebar.sidebar-style-2 .nav .nav-item.submenu {
  background: transparent !important;
}

.sidebar.sidebar-style-2 .nav .nav-item a[data-toggle=collapse][aria-expanded=true]:before {
  background: transparent !important;
}

.sidebar.sidebar-style-2 .nav.nav-primary > .nav-item.active > a {
  background: #19a975 !important;
  box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.1), 4px 4px 15px -5px rgba(21, 114, 232, 0.4);
}

.sidebar.sidebar-style-2 .nav.nav-primary > .nav-item.active > a:before {
  background: transparent !important;
}

.sidebar.sidebar-style-2 .nav.nav-primary > .nav-item.active > a p, .sidebar.sidebar-style-2 .nav.nav-primary > .nav-item.active > a i, .sidebar.sidebar-style-2 .nav.nav-primary > .nav-item.active > a .caret, .sidebar.sidebar-style-2 .nav.nav-primary > .nav-item.active > a span {
  color: #ffffff !important;
}

.sidebar.sidebar-style-2 .nav.nav-primary > .nav-item.active > a[data-toggle=collapse][aria-expanded=true] i {
  color: #ffffff !important;
}

.sidebar.sidebar-style-2 .nav.nav-secondary > .nav-item.active > a {
  background: #6861CE !important;
  box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.1), 4px 4px 15px -5px rgba(104, 97, 206, 0.4);
}

.sidebar.sidebar-style-2 .nav.nav-secondary > .nav-item.active > a:before {
  background: transparent !important;
}

.sidebar.sidebar-style-2 .nav.nav-secondary > .nav-item.active > a p, .sidebar.sidebar-style-2 .nav.nav-secondary > .nav-item.active > a i, .sidebar.sidebar-style-2 .nav.nav-secondary > .nav-item.active > a .caret, .sidebar.sidebar-style-2 .nav.nav-secondary > .nav-item.active > a span {
  color: #ffffff !important;
}

.sidebar.sidebar-style-2 .nav.nav-secondary > .nav-item.active > a[data-toggle=collapse][aria-expanded=true] i {
  color: #ffffff !important;
}

.sidebar.sidebar-style-2 .nav.nav-info > .nav-item.active > a {
  background: #48ABF7 !important;
  box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.1), 4px 4px 15px -5px rgba(72, 171, 247, 0.4);
}

.sidebar.sidebar-style-2 .nav.nav-info > .nav-item.active > a:before {
  background: transparent !important;
}

.sidebar.sidebar-style-2 .nav.nav-info > .nav-item.active > a p, .sidebar.sidebar-style-2 .nav.nav-info > .nav-item.active > a i, .sidebar.sidebar-style-2 .nav.nav-info > .nav-item.active > a .caret, .sidebar.sidebar-style-2 .nav.nav-info > .nav-item.active > a span {
  color: #ffffff !important;
}

.sidebar.sidebar-style-2 .nav.nav-info > .nav-item.active > a[data-toggle=collapse][aria-expanded=true] i {
  color: #ffffff !important;
}

.sidebar.sidebar-style-2 .nav.nav-success > .nav-item.active > a {
  background: #31CE36 !important;
  box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.1), 4px 4px 15px -5px rgba(49, 206, 54, 0.4);
}

.sidebar.sidebar-style-2 .nav.nav-success > .nav-item.active > a:before {
  background: transparent !important;
}

.sidebar.sidebar-style-2 .nav.nav-success > .nav-item.active > a p, .sidebar.sidebar-style-2 .nav.nav-success > .nav-item.active > a i, .sidebar.sidebar-style-2 .nav.nav-success > .nav-item.active > a .caret, .sidebar.sidebar-style-2 .nav.nav-success > .nav-item.active > a span {
  color: #ffffff !important;
}

.sidebar.sidebar-style-2 .nav.nav-success > .nav-item.active > a[data-toggle=collapse][aria-expanded=true] i {
  color: #ffffff !important;
}

.sidebar.sidebar-style-2 .nav.nav-warning > .nav-item.active > a {
  background: #FFAD46 !important;
  box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.1), 4px 4px 15px -5px rgba(255, 173, 70, 0.4);
}

.sidebar.sidebar-style-2 .nav.nav-warning > .nav-item.active > a:before {
  background: transparent !important;
}

.sidebar.sidebar-style-2 .nav.nav-warning > .nav-item.active > a p, .sidebar.sidebar-style-2 .nav.nav-warning > .nav-item.active > a i, .sidebar.sidebar-style-2 .nav.nav-warning > .nav-item.active > a .caret, .sidebar.sidebar-style-2 .nav.nav-warning > .nav-item.active > a span {
  color: #ffffff !important;
}

.sidebar.sidebar-style-2 .nav.nav-warning > .nav-item.active > a[data-toggle=collapse][aria-expanded=true] i {
  color: #ffffff !important;
}

.sidebar.sidebar-style-2 .nav.nav-danger > .nav-item.active > a {
  background: #F25961 !important;
  box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.1), 4px 4px 15px -5px rgba(242, 89, 97, 0.4);
}

.sidebar.sidebar-style-2 .nav.nav-danger > .nav-item.active > a:before {
  background: transparent !important;
}

.sidebar.sidebar-style-2 .nav.nav-danger > .nav-item.active > a p, .sidebar.sidebar-style-2 .nav.nav-danger > .nav-item.active > a i, .sidebar.sidebar-style-2 .nav.nav-danger > .nav-item.active > a .caret, .sidebar.sidebar-style-2 .nav.nav-danger > .nav-item.active > a span {
  color: #ffffff !important;
}

.sidebar.sidebar-style-2 .nav.nav-danger > .nav-item.active > a[data-toggle=collapse][aria-expanded=true] i {
  color: #ffffff !important;
}

.sidebar.sidebar-style-2[data-background-color="dark"] .nav .nav-item a:hover p, .sidebar.sidebar-style-2[data-background-color="dark"] .nav .nav-item a:hover i, .sidebar.sidebar-style-2[data-background-color="dark"] .nav .nav-item a:focus p, .sidebar.sidebar-style-2[data-background-color="dark"] .nav .nav-item a:focus i, .sidebar.sidebar-style-2[data-background-color="dark"] .nav .nav-item a[data-toggle=collapse][aria-expanded=true] p, .sidebar.sidebar-style-2[data-background-color="dark"] .nav .nav-item a[data-toggle=collapse][aria-expanded=true] i, .sidebar.sidebar-style-2[data-background-color="dark2"] .nav .nav-item a:hover p, .sidebar.sidebar-style-2[data-background-color="dark2"] .nav .nav-item a:hover i, .sidebar.sidebar-style-2[data-background-color="dark2"] .nav .nav-item a:focus p, .sidebar.sidebar-style-2[data-background-color="dark2"] .nav .nav-item a:focus i, .sidebar.sidebar-style-2[data-background-color="dark2"] .nav .nav-item a[data-toggle=collapse][aria-expanded=true] p, .sidebar.sidebar-style-2[data-background-color="dark2"] .nav .nav-item a[data-toggle=collapse][aria-expanded=true] i {
  color: #b9babf !important;
}

.sidebar.sidebar-style-2[data-background-color="dark"] .nav .nav-item.active a, .sidebar.sidebar-style-2[data-background-color="dark2"] .nav .nav-item.active a {
  color: #ffffff;
}

.sidebar.sidebar-style-2[data-background-color="dark"] .nav .nav-item.active a p, .sidebar.sidebar-style-2[data-background-color="dark"] .nav .nav-item.active a i, .sidebar.sidebar-style-2[data-background-color="dark"] .nav .nav-item.active a .caret, .sidebar.sidebar-style-2[data-background-color="dark"] .nav .nav-item.active a span, .sidebar.sidebar-style-2[data-background-color="dark2"] .nav .nav-item.active a p, .sidebar.sidebar-style-2[data-background-color="dark2"] .nav .nav-item.active a i, .sidebar.sidebar-style-2[data-background-color="dark2"] .nav .nav-item.active a .caret, .sidebar.sidebar-style-2[data-background-color="dark2"] .nav .nav-item.active a span {
  color: #ffffff;
}

.sidebar.sidebar-style-2[data-background-color="dark"] .nav .nav-item.active a[data-toggle=collapse][aria-expanded=true] p, .sidebar.sidebar-style-2[data-background-color="dark"] .nav .nav-item.active a[data-toggle=collapse][aria-expanded=true] i, .sidebar.sidebar-style-2[data-background-color="dark"] .nav .nav-item.active a[data-toggle=collapse][aria-expanded=true] .caret, .sidebar.sidebar-style-2[data-background-color="dark"] .nav .nav-item.active a[data-toggle=collapse][aria-expanded=true] span, .sidebar.sidebar-style-2[data-background-color="dark2"] .nav .nav-item.active a[data-toggle=collapse][aria-expanded=true] p, .sidebar.sidebar-style-2[data-background-color="dark2"] .nav .nav-item.active a[data-toggle=collapse][aria-expanded=true] i, .sidebar.sidebar-style-2[data-background-color="dark2"] .nav .nav-item.active a[data-toggle=collapse][aria-expanded=true] .caret, .sidebar.sidebar-style-2[data-background-color="dark2"] .nav .nav-item.active a[data-toggle=collapse][aria-expanded=true] span {
  color: #ffffff;
}


/*    sidebar minimized   */
@media screen and (min-width: 991px) {
  .sidebar_minimize .main-panel {
    width: calc(100% - 75px);
    transition: all .3s;
  }

  .sidebar_minimize .logo-header {
    width: 75px;
    transition: all .3s;
    padding: 0px;
    text-align: center;
  }

  .sidebar_minimize .logo-header .big-logo {
    margin-right: 0px;
  }

  .sidebar_minimize .logo-header .logo {
    position: absolute;
    transform: translate3d(25px, 0, 0);
    opacity: 0;
  }

  .sidebar_minimize .logo-header .logo img {
    display: none;
  }

  .sidebar_minimize .logo-header .nav-toggle {
    position: absolute;
    left: 50% !important;
    transform: translateX(-50%);
    height: 100%;
    right: 0 !important;
  }

  .sidebar_minimize .sidebar {
    width: 75px;
    transition: all .3s;
  }

  .sidebar_minimize .sidebar .sidebar-wrapper {
    width: 75px;
    transition: all .3s;
  }

  .sidebar_minimize .sidebar .sidebar-wrapper .user {
    padding-left: 0px;
    padding-right: 0px;
  }

  .sidebar_minimize .sidebar .sidebar-wrapper .user [class^="avatar-"] {
    float: none !important;
    margin: auto;
  }

  .sidebar_minimize .sidebar .sidebar-wrapper .user .info {
    display: none;
  }

  .sidebar_minimize .sidebar .sidebar-wrapper .user .info span {
    display: none;
  }

  .sidebar_minimize .sidebar .sidebar-wrapper .nav-item {
    position: relative;
  }

  .sidebar_minimize .sidebar .sidebar-wrapper .nav-item a .letter-icon {
    display: block !important;
  }

  .sidebar_minimize .sidebar .sidebar-wrapper .nav-item a i {
    margin-right: unset;
  }

  .sidebar_minimize .sidebar .sidebar-wrapper .nav-item a .badge, .sidebar_minimize .sidebar .sidebar-wrapper .nav-item a span, .sidebar_minimize .sidebar .sidebar-wrapper .nav-item a .caret, .sidebar_minimize .sidebar .sidebar-wrapper .nav-item a p {
    display: none;
    transition: all .3s;
  }

  .sidebar_minimize .sidebar .sidebar-wrapper .nav-item a .sidebar-mini-icon {
    display: block !important;
    margin-right: 0px;
  }

  .sidebar_minimize .sidebar .sidebar-wrapper .nav-item.submenu .nav-collapse, .sidebar_minimize .sidebar .sidebar-wrapper .nav-item.active .nav-collapse {
    display: none;
  }

  .sidebar_minimize .sidebar .sidebar-wrapper .nav-section .text-section {
    display: none;
  }

  .sidebar_minimize .sidebar .sidebar-wrapper .nav-section .sidebar-mini-icon {
    display: block;
  }

  .sidebar_minimize .sidebar:hover {
    width: 250px;
  }

  .sidebar_minimize .sidebar:hover .sidebar-wrapper {
    width: 250px;
  }

  .sidebar_minimize .sidebar:hover .sidebar-wrapper .user {
    padding-left: 15px;
    padding-right: 15px;
  }

  .sidebar_minimize .sidebar:hover .sidebar-wrapper .user [class^="avatar-"] {
    float: left !important;
    margin-right: 11px !important;
  }

  .sidebar_minimize .sidebar:hover .sidebar-wrapper .user .info {
    display: block;
  }

  .sidebar_minimize .sidebar:hover .sidebar-wrapper .user .info span {
    display: block;
  }

  .sidebar_minimize .sidebar:hover .sidebar-wrapper .nav-item a i {
    margin-right: 15px;
  }

  .sidebar_minimize .sidebar:hover .sidebar-wrapper .nav-item a .badge, .sidebar_minimize .sidebar:hover .sidebar-wrapper .nav-item a span, .sidebar_minimize .sidebar:hover .sidebar-wrapper .nav-item a .caret, .sidebar_minimize .sidebar:hover .sidebar-wrapper .nav-item a p {
    display: block;
  }

  .sidebar_minimize .sidebar:hover .sidebar-wrapper .nav-item a .sidebar-mini-icon {
    display: block !important;
    margin-right: 15px;
  }

  .sidebar_minimize .sidebar:hover .sidebar-wrapper .nav-item.submenu .nav-collapse, .sidebar_minimize .sidebar:hover .sidebar-wrapper .nav-item.active .nav-collapse {
    display: block;
  }

  .sidebar_minimize .sidebar:hover .sidebar-wrapper .nav-section .sidebar-mini-icon {
    display: none;
  }

  .sidebar_minimize .sidebar:hover .sidebar-wrapper .nav-section .text-section {
    display: block;
  }

  .sidebar_minimize.sidebar_minimize_hover .logo-header {
    width: 250px;
    padding-left: 25px;
    padding-right: 25px;
    text-align: left;
  }

  .sidebar_minimize.sidebar_minimize_hover .logo-header .logo {
    opacity: 1 !important;
    transform: translate3d(0, 0, 0) !important;
    position: relative !important;
  }

  .sidebar_minimize.sidebar_minimize_hover .logo-header .logo img {
    display: inline-block !important;
  }

  .sidebar_minimize.sidebar_minimize_hover .main-panel {
    width: calc(100% - 250px);
  }

  .sidebar_minimize_hover .logo-header .nav-toggle {
    right: 18px !important;
    transform: translateX(0%) !important;
    left: unset !important;
  }
}

.quick_sidebar_open .quick-sidebar {
  right: 0px !important;
}

.quick_sidebar_open .rtl .quick-sidebar {
  left: 0px !important;
  right: unset !important;
}

@media (max-width: 991px) {
  .sidebar {
    position: fixed;
    left: 0 !important;
    right: 0;
    -webkit-transform: translate3d(-270px, 0, 0);
    -moz-transform: translate3d(-270px, 0, 0);
    -o-transform: translate3d(-270px, 0, 0);
    -ms-transform: translate3d(-270px, 0, 0);
    transform: translate3d(-270px, 0, 0) !important;
    transition: all .5s;
    margin-top: 45px;
  }
}

/* careet */
.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

/* main v2 */

.main {
  margin-left: 250px;
  transition: all .3s;
}

.sidebar_minimize .main {
  margin-left: 75px;
}

.sidebar_minimize.sidebar_minimize_hover .main {
  margin-left: 250px;
}

/* scrollbar */

ng-scrollbar-y.ng-scrollbar-visible {
  border: none;
  margin: 0;
  padding: 0 !important;
  position: absolute;
  z-index: 10;
  right: 2px;
}


/* headbar */

.brand-minimized .app-header.navbar .navbar-brand {
  background-image: none;
}

header.navbar .navbar-brand {
  background-color: #21c485 !important;
  border: 0;
  background-image: none;
  text-align: left;
  overflow: hidden;
}

header.navbar .navbar-brand > .logo {
  background-image: url("../img/v3/logo-symbol-lxz.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 2em;
  width: 2em;
  background-position: center;
  display: inline-block;
  margin-left: 0.8em;
  vertical-align: middle;
}

.brand-minimized .sidebar_minimize .app-header.navbar .navbar-brand > span {
  display: none;
}

.brand-minimized .app-header.navbar .navbar-brand > span,
.sidebar_minimize.sidebar_minimize_hover .app-header.navbar .navbar-brand > span {
  display: inline-block;
}


header.navbar .navbar-brand > span {
  color: #fff;
  font-size: 1.3em;
  vertical-align: middle;
  margin-left: 0.5em;
  display: none;
}

header.navbar {
  background-color: #19a975 !important;
}

header.navbar .navbar-brand > span > img {
  height: 1.2em;
}


@media (min-width: 990px) {
  header.app-header.navbar > li {
    position: relative;
    top: inherit;
    left: 30px;
    color: #fff;
    text-align: left;
  }
}

@media (max-width: 991px) {
  .nav_open .sidebar {
    margin-top: 45px;
    -webkit-transform: translate3d(0px, 0, 0);
    -moz-transform: translate3d(0px, 0, 0);
    -o-transform: translate3d(0px, 0, 0);
    -ms-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0) !important;
    border-right: 1px solid #f1f1f1;
  }

  .app-header.navbar .navbar-brand {
    display: none;
  }
}

@media screen and (min-width: 991px) {
  .sidebar_minimize.sidebar_minimize_hover .app-header.navbar .navbar-brand {
    width: 250px;
  }
}


/* main */

main.main {
  min-height: calc(100vh - 45px);
}


/* btn v2 */

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: none;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
}

/* btn-sm */
.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.76562rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn:not(.btn-link) {
  margin: 0.2rem;
}

/* temporary btn */
.btn-outline-dark {
  border: 1px solid #383e4b;
}

.btn-outline-secondary {
  border: 1px solid #c7cbd5;
  box-shadow: none;
}

.btn-outline-gray-200 {
  border: 1px solid #e4e6eb;
}

.btn-outline-gray-300 {
  border: 1px solid #c7cbd5;
}

.btn.btn-outline-primary {
  box-shadow: none;
  color: #19a975;
  border: 1px solid #19a975;
}

.btn.btn-outline-primary:visited,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn.btn-outline-primary:hover {
  color: #fff;
  background-color: #19a975;
}

.btn[disabled] {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, .2), 0 0 0 0 rgba(0, 0, 0, .14), 0 0 0 0 rgba(0, 0, 0, .12);
}


.btn-info,
.btn-info:hover {
  color: #fff;
  background-color: #21c485;
  border-color: #21c485;
}

.btn-info:focus,
.btn-info.focus {
  box-shadow: inherit;
}

.btn-info.disabled,
.btn-info:disabled {
  color: #00000042;
  background-color: #0000001f;
  border-color: #0000001f;
}

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #26EAA0;
  border-color: #26EAA0;
}

.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0px 3px 10px 0.1rem rgba(0, 0, 0, 0.28);
}

.btn-warning,
.btn-warning:hover {
  color: #fff;
  background-color: #FF9800;
  border-color: #FF9800;
}

.btn-warning2,
.btn-warning2:hover {
  color: #fff;
  background-color: #FABB3C;
  border-color: #FABB3C;
}

.btn-warning:focus,
.btn-warning.focus,
.btn-warning2:focus,
.btn-warning2.focus {
  box-shadow: inherit;
}

.btn-warning.disabled,
.btn-warning:disabled {
  color: #00000042;
  background-color: #0000001f;
  border-color: #0000001f;
}

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #f9aa0b;
  border-color: #f1a306;
}

.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0px 3px 10px 0.1rem rgba(0, 0, 0, 0.28);
}

.btn-danger,
.btn-danger:hover {
  color: #fff;
  background-color: #E53935;
  border-color: #E53935;
}

.btn-danger:focus,
.btn-danger.focus {
  box-shadow: inherit;
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: #00000042;
  background-color: #0000001f;
  border-color: #0000001f;
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #ff2121;
  border-color: #ff1414;
}

.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0px 3px 10px 0.1rem rgba(0, 0, 0, 0.28);
}


.btn.btn-outline-danger {
  box-shadow: none;
  color: #ff2121;
  border: 1px solid #ff2121;
}

.btn.btn-outline-danger:visited,
.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn.btn-outline-danger:hover {
  color: #fff;
  background-color: #ff2121;
}

.btn-success,
.btn-success:hover {
  color: #fff;
  background-color: #0068bf;
  border-color: #0068bf;
}

.btn-success:focus,
.btn-success.focus {
  box-shadow: inherit;
}

.btn-success.disabled,
.btn-success:disabled {
  color: #00000042;
  background-color: #0000001f;
  border-color: #0000001f;
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #0277BD;
  border-color: #0277BD;
}

.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0px 3px 10px 0.1rem rgba(0, 0, 0, 0.28);
}

.btn-primary,
.btn-primary:hover {
  color: #fff;
  background-color: #19a975;
  border-color: #19a975;
}

.btn-primary:focus,
.btn-primary.focus {
  box-shadow: inherit;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #00000042;
  background-color: #0000001f;
  border-color: #0000001f;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #21c485;
  border-color: #21c485;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0px 3px 10px 0.1rem rgba(0, 0, 0, 0.28);
}


.btn-link {
  font-weight: 400;
  color: #1c7aea;
  background-color: transparent;
  box-shadow: none;
}

.btn-link:hover {
  color: #1a7fb0;
  /* text-decoration: underline; */
  background-color: transparent;
  border-color: transparent;
}

.btn-link:focus,
.btn-link.focus {
  text-decoration: none;
  border-color: transparent;
  box-shadow: none;
  outline: none;
  border: 0;
}

.btn-link:disabled,
.btn-link.disabled {
  color: #00000042;
}

.btn-secondary,
.btn-secondary:hover {
  color: #151b1e;
  background-color: #c7cbd5;
  border-color: #c7cbd5;
}

.btn-secondary:focus,
.btn-secondary.focus {
  box-shadow: inherit;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #00000042;
  background-color: #0000001f;
  border-color: #0000001f;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #151b1e;
  background-color: #aab0bf;
  border-color: #a3a9ba;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0px 3px 10px 0.1rem rgba(0, 0, 0, 0.28);
}

.btn:hover:not(.btn-link):not(:disabled) {
  box-shadow: 0px 3px 10px 0.1rem rgba(0, 0, 0, 0.28);
}


.btn-outline-white {
  color: #ffffff;
  background: inherit;
  border: solid 1px #ffffff;
}


/* modal v2 */
.modal-header,
.modal-primary .modal-header {
  background-color: #19a975;
}

.modal-header .close {
  padding: 0.2rem;
  margin: 0rem;
}

.modal-header .close i {
  font-size: 20px;
}


/* page */
.ngx-pagination .current {
  background-color: #19a975 !important;
}

.ngx-pagination .current,
.ngx-pagination a {
  border-radius: 20px !important;
}


/* bg */

.bg-info {
  background-color: #0068bf !important;
}

.bg-primary {
  background-color: #42A5F5 !important;
}

.bg-green {
  background-color: #21c485 !important;
}

.bg-light-green {
  background-color: #21c997 !important;
}

/* color */
.text-info {
  color: #0068bf !important;
}

.text-success {
  color: #4CAF50 !important;
}

.text-c22 {
  color: #222222;
}

.text-c33 {
  color: #333333;
}

.text-c99 {
  color: #999999;
}

.text-cdd {
  color: #dddddd;
}

/* card */
.card.v2 {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #e4e6eb;
  border-radius: 0.25rem;
}

/* table v2*/

/* fix head scroll table */
.table-fix-scroll {
}

.table-fix-scroll tbody {
  display: block;
  max-height: 24em;
  /* firefox */
  overflow-y: scroll;
  /* chrome */
  overflow-y: overlay;
  -webkit-overflow-scrolling: touch;
}

.table-fix-scroll.sm-scroll tbody {
  max-height: 15em;
}

.table-fix-scroll.lg-scroll tbody {
  max-height: 40em;
}

.table-fix-scroll tbody::-webkit-scrollbar {
  /*display: none;*/
}

/* 滚动条样式 */
.table-fix-scroll tbody::-webkit-scrollbar {
  width: 4px; /*  设置纵轴（y轴）轴滚动条 */
  /*height: 4px; !*  设置横轴（x轴）轴滚动条 *!*/
}

/* 滚动条滑块（里面小方块） */
.table-fix-scroll tbody::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: transparent;
}

/* 滚动条轨道 */
.table-fix-scroll tbody::-webkit-scrollbar-track {
  border-radius: 0;
  background: transparent;
}

/* hover时显色 */
.table-fix-scroll tbody:hover::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 2px;
}

.table-fix-scroll tbody:hover::-webkit-scrollbar-track {
  background: transparent;
}


.table-fix-scroll thead, .table-fix-scroll tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

/*.table-fix-scroll thead {*/
/*width: calc(100% - 1em);*/
/*}*/

/*.table-bordered.table-fix-scroll thead {*/
/*width: calc(100% - 1em - 3px);*/
/*}*/

.table-bordered.table-fix-scroll.v2 {
  border: none;
}

.table-bg-gray {
  background: #f3f3f9
}

.table-bg-white {
  background: #ffffff
}

.table.v2 th, .table.v2 td {
  padding: 0.2rem 0.5rem;
  vertical-align: top;
  border: inherit;
  border-top: 1px solid #e4e6eb;
}

.table.table-bordered.v2 th,
.table.table-bordered.v2 td {
  border: 1px solid #ccc;
}

.card-block {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem;
}

/* scroll */
.scroll.v2 {
  display: block;
  max-height: 24em;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.scroll.v2.scroll-min {
  max-height: 6em;
}

/* layout */
.col-9-1 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-9-1 {
  -ms-flex: 0 0 11.1111%;
  flex: 0 0 11.1111%;
  max-width: 11.1111%;
}

.text-p {
  padding: 0.375rem 0.75rem
}

.text-underline {
  text-decoration: underline;
}

/* chart */
.highcharts-credits {
  display: none;
}

.login-logo {
  width: 10em;
}

/* mobile */
/* show1(table) d-mobile(div) 手机 show2(table) d-web(div) 电脑 */
@media (min-width: 768px) {
  .d-mobile {
    display: none;
  }

  .d-web {
    display: block;
  }

  .show1 {
    display: none !important;
  }

  .show2 {
    display: table-cell;
  }
}

@media (max-width: 767px) {
  .d-mobile {
    display: block;
  }

  .d-web {
    display: none;
  }

  .show1 {
    display: table-cell;
  }

  .show2 {
    display: none !important;
  }

  .login-logo {
    width: 8em;
  }
}


/**/

circle-progress > svg > text > tspan:first-of-type {
  font-size: 15px;
}


.main {
  background-color: #f3f3f9;
}

/* tab */
.v2.tab-content {
  margin-top: -1px;
  background: #fff;
  border: none;
  height: calc(100% - 36px);
  overflow-y: scroll;
  overflow-x: hidden;
  position: absolute;
  width: 100%;
}

.nav-tabs .nav-link span,
.nav-tabs .nav-link i,
.nav-tabs .nav-link a {
  color: #9da0a8;
  text-decoration: none;
}

.tab-content .tab-pane {
  padding: 0;
  overflow: hidden;
}

/* text */
.text {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  display: inline-block;
}

.btn-link {
  font-size: 1rem;
}

/* cursor: pointer;*/
.cursor {
  cursor: pointer;
}

/* fix ngx-select */
.ngx-select__toggle.btn {
  border: 1px solid #e4e6eb;
  box-shadow: none;
}

.ngx-select__toggle.btn:hover {
  box-shadow: none;
}

/* loading */
.loading {
  border-radius: 0;
  position: fixed;
  z-index: 1999;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
}

.loading > .loading-frame {
  display: inline-block;
  border-left: solid .3em #21c485;
  border-radius: 0.1em;
  background: #fff;
  font-size: 1.4em;
  box-shadow: 0px 0px 5px 1px rgba(215, 215, 215, 0.3)
}

/* 充值弹窗 */
.money-div {
  background-color: rgba(242, 90, 99, 1) !important;
  display: block;
}

.money-img {
  width: 32px;
  height: 32px;
  display: block;
  float: left;
  margin-right: 14px;
}

.money-img2 {
  width: 30px;
  height: 30px;
  margin-right: 5px;
}

.money-title {
  line-height: 30px !important;
  font-size: 24px;
}

.money-content {
  min-height: 250px;
}

.money-sigle-p {
  padding-top: 100px;
  padding-left: 40px;
}

.money-total {
  margin-top: 10px;
  margin-bottom: 10px;
  /*height: 77px;*/
  background: rgba(255, 255, 204, 1);
  line-height: 77px;
  width: 100%;
  padding-left: 20px;
  margin-left: 0px;
}

.money-amount {
  font-weight: 700;
  font-size: 20px;
  color: #FF0000;
}

.money-body {
  padding: 0px;
  color: #333;
}

.money-pay {
  padding-left: 20px;
  margin-bottom: 30px;
  /*height: 48px;*/
}

.money-span {
  width: 89px;
  height: 48px;
  display: inline-block;
  /*float: left;*/
  border-radius: 5px;
  text-align: center;
  line-height: 42px;
  border: 2px solid white;
  position: relative;
  overflow: hidden;
}

.money-span2 {
  margin-right: 5px;
  display: block;
  float: left;
  height: 48px;
  line-height: 48px;
}

/*@media screen and (max-width: 768px) {*/
/*  .money-span2 {*/
/*    display: inline;*/
/*  }*/

/*  .money-span2::after {*/
/*    content: "";*/
/*    clear: both;*/
/*  }*/
/*}*/

.money-span:hover {
  border: 2px solid rgba(88, 102, 221, 1);
}

.money-max-width {
  max-width: 850px;
}


/* angular-calendar */
.cal-month-view .cal-day-number {
  font-size: 2.2em;
  font-weight: 400;
  opacity: 0.5;
  margin-top: 5px;
  margin-right: 15px;
  float: right;
  margin-bottom: 10px;
}

.cal-month-view .cal-day-cell.cal-today .cal-day-number {
  font-size: 2.9em;
}

.cal-month-view .cal-day-cell {
  min-height: 60px;
}

.guide-img {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
}


/* 收银台 */

.money-color {
  font-weight: 700;
  font-size: 28px;
  color: #F25A63;
}

.payIcon {
  position: absolute;
  left: 30px;
  top: 20px;
}

.payImg {
  height: 30px;
}

.pay_headerbg {
  background-color: rgba(242, 90, 99, 1);
}

.zeroBtn {
  width: 119px;
  height: 35px;
  background-color: rgba(242, 90, 99, 1);
  border-radius: 5px;
  color: #fff;
  margin-top: 5px;
}


.ant-progress-inner {
  background-color: #0068bf;
}


/* single page */

.ob-header {
  z-index: 1020;
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: #19a975;
  height: 46px;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.10);
  box-sizing: border-box;
  display: flex;
}

.ob-navbar {
  background-color: #21d18e;
  border: 0;
  background-image: none;
  text-align: left;
  overflow: hidden;
  height: 45px;
  width: 75px;
  padding: .5rem 1.5rem;
  margin-right: 0;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.ob-navbar .logo {
  color: #fff;
  background-position: center;
  display: inline-block;
}

.ob-navbar .logo i {
  font-size: 2.5em;
}

.ob-header li {
  position: relative;
  top: inherit;
  color: #fff;
  text-align: left;
  list-style-type: none;
  margin: auto 2em;
  padding: 0;
}

.ob-header li > span {
  font-size: 1.2em;
}

/* */
.share-pic img {
  width: 100%;
}


/* table frozen */
.table-bordered {
  border: 1px solid #c0b9b95e;
  border-radius: 5px;
}

.table-striped tbody tr:nth-of-type(odd) td {
  background-color: #f0f4f6;
}

/* custom zorro */
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 0.2rem 0.5rem;
}

.ant-table table {
  width: 100%;
  /* firefox border bug border-collapse: separate; */
  border-collapse: collapse;
  border-spacing: 0;
  text-align: center;
  border-radius: 0;
}

.ant-table-thead > tr > th {
  background: #fff;
  text-align: center;
}

/* zorro upload */
.move-actions {
  opacity: 0;
  transition: all .3s;
}

.ant-upload-list-picture-card .ant-upload-list-item-info:hover + .move-actions,
.ant-upload-list-picture-card .move-actions:hover {
  opacity: 1;
}

/* custom ng-select */

.ng-select {
  min-width: 143px;
}

.ng-select .ng-select-container {
  min-height: 32px;
}

.ng-select.ng-select-single .ng-select-container {
  height: 32px;
}

.ng-select .ng-select-container .ng-value-container .ng-input > input {
  height: 28px;
  line-height: 30px;
}

.ng-select .ng-select-container .ng-value-container,
.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  padding-top: 1px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  margin-bottom: 1px;
  background-color: #ebf5ff;
  margin-right: 2px;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder,
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  top: 0;
  height: 32px;
  line-height: 32px;
  padding: 0;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
  padding: 0 0 1px 2px;
}

.ng-value.ng-star-inserted {
  line-height: 26px;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: 0;
}

/*.ng-select {*/
/*  height: calc(2.0625rem + 2px);*/
/*  width: 10em;*/
/*}*/

/*.ng-select.custom {*/
/*  height: inherit;*/
/*  width: 100%;*/
/*}*/

/*span.ng-value-label.ng-star-inserted {*/
/*  transform: none;*/
/*}*/

/*.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {*/
/*  padding: 0.275rem 0.25rem !important;*/
/*}*/

/*.ng-select .ng-select-container .ng-value-container .ng-placeholder {*/
/*  padding-left: 0.25rem !important;*/
/*}*/

/*.ng-placeholder {*/
/*  transform: none !important;*/
/*}*/

/* custom popper */
.popover-title {
  font-size: 1rem;
  color: #383e4b;
}

.popover-body {
  font-size: .875rem;
}

.text-reverse {
  filter: invert(70%);
}

/* custom zorro timepicker */
input[type="text"].ant-time-picker-input,
input[type="text"].ant-time-picker-panel-input {
  width: 100%;
  height: inherit;
}

/* quill */
.ql-editor {
  min-height: 300px;
}

.ql-editor b,
.ql-editor strong,
.ql-editor u,
.ql-editor s,
.ql-editor em,
.ql-editor span {
  font-size: inherit;
}

/* custom ngx-image-gallery */
ngx-image-gallery {
  top: 46px !important;
}

.o-hidden {
  overflow: hidden;
}

/* modal scroll */
.scroll-body {
  max-height: 50em;
  overflow-y: scroll;
  overflow-x: hidden;
}


.mh30 {
  max-height: 30em;
  overflow-y: scroll;
}

.h30 {
  height: 30em;
  overflow-y: scroll;
}

.text-gray1 {
  color: #999999 !important;
}

.text-gray2 {
  color: #666666 !important;
}

.text-green1 {
  color: #21c485;
}

.text-black {
  color: #000000;
}

.text-222 {
  color: #222222 !important;
}

.bg-gray1 {
  background: #f2f2f2 !important;
}

.btn-label {
  padding: 0.375rem 0;
  /*font-size: 0.875rem;*/
  /*line-height: 1.5;*/
}

.w120 {
  width: 120px;
}

.w200 {
  width: 200px;
}

.w300 {
  width: 300px;
}

.w500 {
  width: 500px;
}


/**/
.full-loading-mask {
  width: 100vw;
  height: 100vh;
  background-color: #000000;
  opacity: .6;
  position: absolute;
}

.full-loading-mask i {
  margin-top: 40%;
}

@media screen and (max-width: 768px) {
  .scrollx-head {
    overflow-x: scroll;
    padding: 0 !important;
  }

  .scrollx-body {
    min-width: max-content;
  }

  .scrollxy-width80 {
    min-width: auto;
    max-width: 200em;
    width: 80em;
  }


  .w75px-sm {
    width: 75px !important;
  }

  .w75px-ex-sm {
    width: calc(100% - 75px) !important;
  }

  /* 监控手机横屏 */
  .monitor-bg {
    transform: rotate(90deg) translateY(-100%);
    transform-origin: top left;
    width: 100vh;
    height: 100vw;
    overflow-x: scroll;
    /*position: absolute;*/
    /*left: 0;*/
    /*top: 0;*/
  }
}


/* project-accordion */
.project-group .panel .card-header {
  padding: 0.3rem 0;
  border-radius: 5px;
  background-color: #fff;
  border: solid 1px #f0f2f7;
}

.project-group .panel.panel-open .card-header {
  border: solid 1px rgb(25 169 117 / 20%);
}

.project-group .panel.panel-open .card-header.panel-enabled {
  background: #19a97533;
}

.project-group .card-header button {
  color: #000000;
}

.project-group .card-block {
  padding: 0.75rem 1.25rem;
}


.project-group .card-header.panel-enabled button {
  color: #000;
}

.project-group .panel.panel-open .card-header.panel-enabled button {
  color: #19a975;
}

.project-group .panel-body .active {
  color: #19a975;
}

.pcheck {
  position: relative;
}

.pcheck p {
  position: absolute;
  width: 13px;
  height: 13px;
  border: none;
  border-radius: 50%;
  display: block;
  top: 3px;
  left: 0;
}

/*改变选中时的圆点*/
.pcheck input:checked + p {
  background-color: #fff;
  border: 1px #5cb85c solid;
}

.pcheck input:checked + p > span {
  position: absolute;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: #5cb85c;
  left: 1px;
  top: 1px;
}

.mark {
  background: #f00;
  color: #fff;
  width: 4.5rem;
  height: 4rem;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  line-height: 1.1;
  text-align: center;
  position: absolute;
  left: -2.4rem;
  top: -2.1rem;
  transform: rotate(-45deg);
  font-size: 8px;
  z-index: 9999;
}


/**/

.lqd-login-tab .tab-content {
  border: none;
}

.lqd-login-tab .nav-tabs .nav-link {
  border-color: #ffffff;
  transition: border-color ease .4s;
  border-bottom: 2px solid transparent;
  border-left: none;
  border-right: none;
  border-top: none;
}

.lqd-login-tab .nav-tabs .nav-link.active {
  border-color: #21c485;
}

.lqd-login-tab .nav-tabs .nav-link span {
  color: #000000;
  font-weight: bold;
}

.lqd-login-tab .nav-tabs .nav-link.active span {
  color: #21c485;
}

.overflow-hidden {
  overflow: hidden !important;
}


.text-btn {
  padding: 6px 12px;
}

.btn .badge {
  font-family: monospace;
}

.label-search {
  height: 22px;
}

.label-search::after {
  content: "";
  display: inline-block;
  height: 3.425em;
}

.text-wrap-balance {
  text-wrap: inherit !important;
  text-wrap: balance !important;
  /*safari*/
  white-space: break-spaces;
}

.mr-1px {
  margin-right: 1px;
}

.modal-dialog {
  margin-top: 40px !important;
}


.visual-mobile {
  width: 460px;
  height: 844px;
  display: inline-block;
  overflow-y: scroll;

  word-break: break-all;
}

.visual-mobile-fix {
  width: 460px;
  height: 844px;
  display: inline-block;
}

.visual-mobile img {
  max-width: 100%;
}

.visual-mobile {
  font-size: 15px;
  line-height: 23px;
}

.visual-mobile p {
  color: #666666;
}

.notice-webview {
  font-size: 15px;
  line-height: 23px;
}

.notice-webview p {
  font-size: 15px;
  line-height: 23px;
  color: #666666;
  margin-block-start: 1em;
  margin-block-end: 1em;
  word-break: break-all;
}

ngx-extended-pdf-viewer .html .toolbarField.pageNumber {
  height: 14px;
}

.ql-container {
  height: fit-content;
}

.limit-length {
  width: 10em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}

.limit-length.lg {
  width: 20em;
}

.limit-length.xl {
  width: 30em;
}

.overflow-scroll {
  overflow-y: scroll;
}

.mh-500 {
  min-height: 500px
}

.max-height {
  max-height: 500px;
}
