/* 搜索条件样式end */
.setbox-top {
  border-radius: 4px;
  overflow: hidden;
}

.setbox {
  padding: 15px;
  background: #fff;
  overflow: visible;
  border-radius: 4px;
}

.sealist-r {
  width: 80px;
  flex: 1;
  text-align: right;
}

.sealist {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-end;
}

.item p {
  display: block;
  padding: 0 10px 0 0;
  width: 100px;
  float: left;
  text-align: right;
}

.selectbox {
  display: flex;
  flex-wrap: wrap;
}

.mr10 {
  margin-right: 10px
}

.item select,
.item textarea {
  width: 143px;
}

/* .item {
  margin-right: 2%;
} */
/* 搜索条件样式end */
/* 标签样式 */
.bg-gray {
  background-color: #F0F2F7;
  color: #333;
}

.padd0 {
  padding: 0;
}

.pad15 {
  padding: 0 15px;
}

.text-yellow1 {
  color: #FFFF00;
}

.yellow {
  background: #FFFFCC;
  padding-top: 10px;
  margin-bottom: 10px;
}

.padd15 {
  padding: 15px;
  /* border: 1px solid #ccc; */
}

.bg-primary,
.bg-success,
.bg-info,
.bg-warning,
.bg-danger,
.bg-inverse,
.bg-gray {
  padding: 0.6rem 1rem;
}

input.form-control {
  height: 32px;
  line-height: 32px;
}

.mr20 {
  margin-right: 20px !important;
}

.table-box,
.table-box-lg {
  width: 100%;
  max-height: 462px;
  overflow: auto;
}

.table-box-min {
  width: 100%;
  max-height: 209px;
  overflow: auto;
}

textarea {
  border: 1px solid #e4e6eb;
  border-radius: 4px;
}

.w100px {
  width: 100px !important;
  float: left;
}

.table-box table {
  min-width: 500px;
  width: 100%;
}

.table-box-min table {
  min-width: 500px;
  width: 100%;
}

.table-box-lg table {
  min-width: 700px;
  width: 100%;
}

.w90px {
  width: 90px !important;
  float: left;
}

.condiv {
  float: left;
  width: calc(100% - 90px) !important;
}

.w70px {
  width: 70px !important;
  display: inline-block;
}

.condiv70 {
  display: inline-block;
  width: calc(100% - 70px) !important;
}

.w80px {
  width: 80px !important;
  display: inline-block;
}

.w105px {
  width: 105px !important;
  display: inline-block;
}

.condiv1 {
  float: left;
  width: calc(100% - 100px) !important;
}

.w100 {
  width: 100px !important;
}

.w120 {
  width: 120px !important;
}

.w140 {
  width: 140px !important;
}

.w150 {
  width: 150px !important;
}

.w200 {
  width: 200px !important;
}

.table.table.table-bordered.table-striped td span {
  display: inline-block;
}

@media (min-width: 320px) {
  .show1 {
    display: table-cell;
  }

  .show2 {
    display: none;
  }

  .show3 {
    display: inline-block !important;
  }

  .show4 {
    display: none !important;
  }

  .container-fluid {
    padding: 0 !important;
  }
}

@media (min-width: 768px) {
  .show2 {
    display: table-cell;
  }

  .show1 {
    display: none;
  }

  .show3 {
    display: none !important;
  }

  .show4 {
    display: inline-block !important;
  }

  .modal-sm {
    max-width: 500px;
  }

  .container-fluid {
    padding: 0 15px !important;
  }
}

/* 标签样式end */
/*modal*/
.modal .modal-footer {
  justify-content: center !important;
}

/*modal*/
/*table*/
.table-scroll table {
  margin: 0 auto;
  border-collapse: collapse;
}

.table-scroll thead {
  display: block
}

.table-scroll tbody {
  height: 200px;
  overflow-y: scroll;
  display: block
}

/*table*/
/* 弹窗样式 */
.list-1 {
  width: 6rem;
  float: left;
}

.list-2 {
  width: 3rem;
  float: left;
}

.list-3 {
  width: 8rem;
  float: left;
}

.mr5 {
  margin-right: 5px;
}

.con-body .col-12.col-lg-5.mb10 {
  padding-right: 0;
}

.trans input.form-check-input,
.divbox input.form-check-input {
  transform: translateY(4px);
}

.money-list.text-center a {
  display: inline-block;
  width: 66px;
}

.money-list.text-center a img {
  width: 100%;
}

.money-list.text-center img {
  display: inline-block;
  width: 66px;
}

p.big {
  margin-bottom: 20px;
}

p.big span {
  font-weight: bold;
}

.input-box ul {
  display: flex;
  flex-wrap: wrap;
}

.input-box ul li:nth-child(3) {
  width: 163px;
}

.input-box ul li {
  margin-right: 2%;
}

i.fa.fa-times.fa-size {
  font-size: 22px;
  line-height: 29px;
}

.dialog-padd span {
  display: inline-block;
}

.b-blue {
  color: #000099;
}

.b-blues {
  color: #3366FF;
}

.b-blue1 {
  color: #0000FF;
}

.black {
  color: #000;
}

.font12 {
  font-size: 12px;
}

.font16 {
  font-size: 16px;
}

.font20 {
  font-size: 20px;
}

.font18 {
  font-size: 18px;
  font-weight: bold;
}

.text-orange {
  color: #FF6633 !important;
}

.mb40 {
  margin-bottom: 40px;
}

.over12 {
  max-height: 472px;
  overflow-y: auto;
}

.condiv-flex {
  display: flex;
  flex-wrap: wrap;
}

.condiv-flex .divbox {
  margin-right: 2%;
}

.condiv-flex .divbox.w166 {
  width: 166px;
}

ul.img-box {
  margin-left: 0;
  padding-left: 0;
}

.check-box {
  clear: both;
  overflow: hidden;
}

.b-blue2 {
  color: #0066FF;
}

.w60px {
  width: 60px !important;
  float: left;
}

.condiv60 {
  width: calc(100% - 60px) !important;
  float: left;
}

.w120px {
  width: 120px !important;
  float: left;
}

.condiv2 {
  width: calc(100% - 120px) !important;
  float: left;
}

.w140px {
  width: 140px !important;
  float: left;
}

.condiv3 {
  width: calc(100% - 140px) !important;
  float: left;
}

.w200px {
  width: 200px !important;
  float: left;
}

.w300px {
  width: 300px !important;
  float: left;
}

.condiv4 {
  width: calc(100% - 200px) !important;
  float: left;
}

ul.img-box li {
  width: 96px;
  height: 56px;
  border: 1px solid #ccc;
  display: inline-block;
  overflow: hidden;
  margin-right: 8px;
}

ul.img-box li img {
  max-width: 100%;
  height: 100%;
}

textarea {
  min-height: 32px;
}

@media (min-width: 1500px) {
  .container {
    max-width: 1380px;
  }

  .col-2xl-1,
  .col-2xl-2,
  .col-2xl-3,
  .col-2xl-4,
  .col-2xl-5,
  .col-2xl-6,
  .col-2xl-7,
  .col-2xl-8,
  .col-2xl-9,
  .col-2xl-10,
  .col-2xl-11,
  .col-2xl-12,
  .col-2xl,
  .col-2xl-auto {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }

  .mt-2xl-0 {
    margin-top: 0 !important;
  }

  .col-2xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-2xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-2xl-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-2xl-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-2xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-2xl-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-2xl-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-2xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-2xl-7 {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-2xl-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-2xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-2xl-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-2xl-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-2xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 1800px) {
  .container {
    max-width: 1500px;
  }

  .col-3xl-1,
  .col-3xl-2,
  .col-3xl-3,
  .col-3xl-4,
  .col-3xl-5,
  .col-3xl-6,
  .col-3xl-7,
  .col-3xl-8,
  .col-3xl-9,
  .col-3xl-10,
  .col-3xl-11,
  .col-3xl-12,
  .col-3xl,
  .col-3xl-auto {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }

  .mt-3xl-0 {
    margin-top: 0 !important;
  }

  .col-3xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-3xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-3xl-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-3xl-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-3xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-3xl-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-3xl-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-3xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-3xl-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-3xl-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-3xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-3xl-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-3xl-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-3xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

ul.nav.navbar-nav.ml-auto li a,
ul.nav.navbar-nav.ml-auto li>div>.btn-link {
  color: #fff !important;
  font-size: 14px;
  border: 0;
}

ul.nav.navbar-nav.ml-auto li {
  position: relative;
}

.red-tips {
  width: 26px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  background: red;
  border-radius: 100%;
  position: absolute;
  top: -5px;
  right: -13px;
  font-style: normal;
  color: #fff;
  font-size: 12px;
}

@media (max-width: 360px) {
  span.d-md-down-none {
    display: none;
  }

  header.app-header.navbar > li {
    width: 48%;
    left: 10px;
    position: absolute;
    top: 10px;
    color: #fff;
  }
}

@media (max-width: 768px) {
  .judge {
    display: none;
  }

  ul.nav.navbar-nav.ml-auto li a {
    padding-left: 2px;
    padding-right: 0;
  }

  .d-md-down-none {
    /*padding: 0 0.3em;*/
  }
}

@media (min-width: 360px) {
  span.d-md-down-none {
    display: inline-block;
  }

  header.app-header.navbar > li {
    width: 38%;
    position: absolute;
    top: 10px;
    left: 30px;
    color: #fff;
    text-align: left;
  }
}

@media (min-width: 480px) {
  .judge {
    display: inline-block;
  }
}

@media (min-width: 990px) {
  header.app-header.navbar > li {
    left: 80px;
  }
}

header.app-header.navbar > li span {
  vertical-align: middle;
}

.con-body {
  clear: both;
  line-height: 42px;
}

ul,
li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition-delay: 9999999999999s;
  -webkit-transition: color 9999999999999s ease-out, background-color 99999999999s ease-out;
}

input:focus {
  outline: none;
}

p {
  margin-bottom: 0;
}

/*.ng-select.ng-select-single .ng-select-container {*/
/*  height: 32px;*/
/*}*/

/*

  .ng-select .ng-select-container .ng-value-container .ng-input>input{
    padding: 0 3px;
  }

  span.ng-value-label.ng-star-inserted {
    height: 32px;
    display: inline-block;
    line-height: 32px;
} */
/*.ng-value.ng-star-inserted {*/
  /*height: 28px;*/
  /*margin-top: 13px;*/
  /*line-height: 28px;*/
  /*margin-left: 15px;*/
/*}*/

/*span.ng-clear {*/
/*  margin-top: 10px;*/
/*}*/

/* .room ul {
  width: 1160px;
} */

/* .card-body {
  width: 1205px;
} */
button:hover,
a:hover {
  cursor: pointer !important;
}

.dw100 {
  width: 100px !important;
  display: inline-block !important;
}

/*.ng-select.ng-select-single .ng-select-container .ng-value-container {*/
/*  height: 32px;*/
/*  line-height: 32px;*/
/*  padding: 0;*/
/*  margin: 0;*/
/*  min-height: inherit;*/
/*}*/

/*.ng-select .ng-clear-wrapper .ng-clear {*/
/*  line-height: 0;*/
/*}*/

/*.ng-select .ng-arrow-wrapper {*/
/*margin-top: 3px;*/
/*}*/

/*ng-select.ng-select.ng-select-single.ng-select-taggable.ng-select-searchable.ng-untouched.ng-pristine.ng-valid input {*/
/*  margin: 0;*/
/*  padding: 0;*/
/*  transform: translateY(-4px);*/
/*}*/

/*ng-select.ng-select.ng-select-single.ng-select-taggable.ng-select-searchable.ng-untouched.ng-pristine.ng-valid .ng-value.ng-star-inserted {*/
/*  padding: 0;*/
/*  margin: 0 0 0 10px;*/
/*}*/

/*ng-select.ng-select.ng-select-single.ng-select-taggable.ng-select-searchable.ng-untouched.ng-pristine.ng-valid .ng-select .ng-clear-wrapper {*/
/*  transform: translateY(-4px)*/
/*}*/

/*ng-select.ng-select.ng-select-single.ng-select-taggable.ng-select-searchable.ng-untouched.ng-pristine.ng-valid .ng-select .span.ng-arrow-wrapper {*/
/*  transform: translateY(-3px)*/
/*}*/

.ant-upload-list-picture-card .ant-upload-list-item-info:before {
  top: 0;
  left: 0;
}

input.w60px {
  padding: 0 5px !important;
}

.table thead th,
.table tbody td {
  word-break: break-word;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: #aaa;
  /*padding-left: 18px;*/
}

.ng-value.ng-star-inserted {
  padding: 0;
  margin: 0;
}

/*span.ng-clear-wrapper.ng-star-inserted {*/
/*  line-height: 30px;*/
/*}*/

/*.ng-placeholder {*/
/*  transform: translateY(3px);*/
/*}*/

/*span.ng-value-label.ng-star-inserted {*/
  /*transform: translateY(3px);*/
  /*display: block;*/
/*}*/

.money-list img {
  cursor: pointer;
}

.orange {
  color: #FF6600;
}

.text-orange {
  color: #FF6600;
}

.table-box-auto {
  overflow-x: auto;
}

.table-box-auto table {
  min-width: 900px;
}

/*span.text-primary {*/
/*cursor: pointer;*/
/*}*/

/*span.ng-value-label.ng-star-inserted {*/
/*  padding-left: 8px;*/
/*}*/

.pointer {
  cursor: pointer;
}

.modal-body .tab-content {
  border: 0 !important;
}

.cicon {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: text-bottom;
}

.cicon-check {
  background-image: url("../img/icon-check.png");
  background-size: 100% 100%;
}

.cicon-due {
  background-image: url("../img/icon-due.png");
  background-size: 100% 100%;
}

.cicon-lease {
  background-image: url("../img/icon-lease.png");
  background-size: 100% 100%;
}

.cicon-own {
  background-image: url("../img/icon-own.png");
  background-size: 100% 100%;
}

.cicon-warn {
  background-image: url("../img/icon-warn.png");
  background-size: 100% 100%;
}

.scroll5 {
  max-height: 199px;
  /* min-height: 199px; */
  overflow-y: auto;
}

.scroll10 {
  max-height: 399px;
  /* min-height: 399px; */
  overflow-y: auto;
}

.over10 {
  width: 100%;
  max-height: 364px;
  overflow: auto;
}

.over15 {
  width: 100%;
  max-height: 529px;
  overflow: auto;
}


/* custom ngx-qrcode2 css */
.qrcode img {
  width: 104px;
  height: 104px;
  display: block;
  float: left;
  margin-right: 6px;
}

/* custom ngx-qrcode2 css */

/* custom ng-qrcode css */
.qrcode canvas {
  display: block;
  float: left;
  margin-right: 6px;
}

/* custom ng-qrcode css */


.ant-modal-close-x {
  width: 80px;
  height: 80px;
  line-height: 80px;
}

/* 以下妖加入 */
.triangle_border {
  position: absolute;
  top: 0;
  right: 0;
  width: 12px;
}

.icon_img {
  width: 19px;
}

.relative {
  position: relative;
}

.table-overflow {
  overflow-y: auto;
  width: 100%;
}

/*
cursor
 */
.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.clear-fix {
  clear: both;
}

.w-125 {
  width: 12.5% !important;
}

.bg-yellow {
  background: #FABB3C;
}


.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  padding: 2px 10px;
}

.my-select .ngx-select__selected-plural {
  background: #F4F4F4 !important;
  padding: 3px 10px;
  border-radius: 5px;
  z-index: 99;
  position: relative;
}

.my-select .ngx-select__selected-plural span {
  font-size: 12px;
  color: #746674;
}

.my-select .ngx-select__selected-plural i {
  font-size: 12px;
  color: #746674;
}

.my-select .ngx-select {
  height: 30px !important;
}

.my-select .ngx-select__search {
  position: absolute;
  top: 0;
  color: transparent;
}

.confirm_scroll {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  min-height: 100px;
  max-height: 300px;
  overflow-y: auto;
}
