@import "../../../node_modules/angular-calendar/css/angular-calendar.css";

@import "../../../node_modules/swiper/css/swiper.css";



/*!
 custon css
 */

/*copy*/

.navbar-expand-lg {
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.navbar-expand-lg .navbar-nav {
  -ms-flex-direction: row;
  flex-direction: row;
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand-lg .navbar-nav .dropdown-menu-right {
  right: 0;
  left: auto;
}

.navbar-expand-lg .navbar-nav .nav-link,
.navbar-expand-lg .navbar-nav .navbar .dropdown-toggle,
.navbar .navbar-expand-lg .navbar-nav .dropdown-toggle {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand-lg>.container,
.navbar-expand-lg>.container-fluid {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.navbar-expand-lg .navbar-collapse {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}

.navbar-expand-lg .navbar-toggler {
  display: none;
}

.navbar-expand-lg .dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
}

.brand-minimized .app-header.navbar .navbar-brand {
  width: 250px;
  background-color: #1e8fc6;
  background-image: url("../img/logo-symbol.png");
  background-size: 24px;
  transition: all .3s;
}

.brand-minimized .sidebar_minimize .app-header.navbar .navbar-brand {
  width: 75px;
}

@media (max-width: 320px) {
  .brand-minimized .app-header.navbar .navbar-brand {
    display: none;
  }
}

/*.sidebar-compact .sidebar .sidebar-nav {*/
  /*width: 150px;*/
/*}*/

/*.sidebar-compact .sidebar .nav {*/
  /*width: 150px;*/
/*}*/

/*.sidebar-compact .sidebar .nav .nav-title {*/
  /*text-align: center;*/
/*}*/

/*.sidebar-compact .sidebar .nav .nav-item {*/
  /*width: 150px;*/
  /*border-left: 0 !important;*/
/*}*/

/*.sidebar-compact .sidebar .nav .nav-item .nav-link,*/
/*.sidebar-compact .sidebar .nav .nav-item .navbar .dropdown-toggle,*/
/*.navbar .sidebar-compact .sidebar .nav .nav-item .dropdown-toggle {*/
  /*text-align: center;*/
/*}*/

/*.sidebar-compact .sidebar .nav .nav-item .nav-link i,*/
/*.sidebar-compact .sidebar .nav .nav-item .navbar .dropdown-toggle i,*/
/*.navbar .sidebar-compact .sidebar .nav .nav-item .dropdown-toggle i {*/
  /*display: block;*/
  /*width: 100%;*/
  /*margin: 0.25rem 0;*/
  /*font-size: 24px;*/
/*}*/

/*.sidebar-compact .sidebar .nav .nav-item .nav-link .badge,*/
/*.sidebar-compact .sidebar .nav .nav-item .navbar .dropdown-toggle .badge,*/
/*.navbar .sidebar-compact .sidebar .nav .nav-item .dropdown-toggle .badge {*/
  /*position: absolute;*/
  /*top: 18px;*/
  /*right: 10px;*/
/*}*/

/*.sidebar-compact .sidebar .nav .nav-item .nav-link.nav-dropdown-toggle::before,*/
/*.sidebar-compact .sidebar .nav .nav-item .navbar .nav-dropdown-toggle.dropdown-toggle::before,*/
/*.navbar .sidebar-compact .sidebar .nav .nav-item .nav-dropdown-toggle.dropdown-toggle::before {*/
  /*top: 30px;*/
/*}*/

/*.sidebar-minimized .hidden-cn {*/
  /*display: none;*/
/*}*/

/*.sidebar-minimized .sidebar {*/
  /*z-index: 1019;*/
/*}*/

/*.sidebar-minimized .sidebar .sidebar-nav {*/
  /*overflow: visible;*/
  /*width: 70px;*/
/*}*/

/*.sidebar-minimized .sidebar .nav {*/
  /*width: 70px;*/
/*}*/

/*.sidebar-minimized .sidebar .nav-divider,*/
/*.sidebar-minimized .sidebar .divider,*/
/*.sidebar-minimized .sidebar .nav-title,*/
/*.sidebar-minimized .sidebar .sidebar-footer,*/
/*.sidebar-minimized .sidebar .sidebar-form,*/
/*.sidebar-minimized .sidebar .sidebar-header {*/
  /*display: none;*/
/*}*/

/*.sidebar-minimized .sidebar .sidebar-minimizer::before {*/
  /*width: 100%;*/
  /*-webkit-transform: rotate(-180deg);*/
  /*transform: rotate(-180deg);*/
/*}*/

/*.sidebar-minimized .sidebar .nav-item {*/
  /*width: 50px;*/
  /*overflow: hidden;*/
  /*border-left: 0 !important;*/
/*}*/

/*.sidebar-minimized .sidebar .nav-item:hover {*/
  /*!*width: 270px;*!*/
  /*overflow: visible;*/
/*}*/

/*.sidebar-minimized .sidebar .nav-item:hover>.nav-link,*/
/*.sidebar-minimized .sidebar .navbar .nav-item:hover>.dropdown-toggle,*/
/*.navbar .sidebar-minimized .sidebar .nav-item:hover>.dropdown-toggle {*/
  /*background: #22262e;*/
/*}*/

/*.sidebar-minimized .sidebar .nav-item:hover>.nav-link i,*/
/*.sidebar-minimized .sidebar .navbar .nav-item:hover>.dropdown-toggle i,*/
/*.navbar .sidebar-minimized .sidebar .nav-item:hover>.dropdown-toggle i {*/
  /*color: #fff;*/
/*}*/

/*.sidebar-minimized .sidebar .nav-link,*/
/*.sidebar-minimized .sidebar .navbar .dropdown-toggle,*/
/*.navbar .sidebar-minimized .sidebar .dropdown-toggle {*/
  /*position: relative;*/
  /*padding-left: 0;*/
  /*margin: 0;*/
  /*white-space: nowrap;*/
  /*border-left: 0 !important;*/
/*}*/

/*.sidebar-minimized .sidebar .nav-link i,*/
/*.sidebar-minimized .sidebar .navbar .dropdown-toggle i,*/
/*.navbar .sidebar-minimized .sidebar .dropdown-toggle i {*/
  /*display: block;*/
  /*float: left;*/
  /*width: 50px;*/
  /*font-size: 18px;*/
/*}*/

/*.sidebar-minimized .sidebar .nav-link .badge,*/
/*.sidebar-minimized .sidebar .navbar .dropdown-toggle .badge,*/
/*.navbar .sidebar-minimized .sidebar .dropdown-toggle .badge {*/
  /*position: absolute;*/
  /*right: 15px;*/
  /*display: none;*/
/*}*/

/*.sidebar-minimized .sidebar .nav-link:hover,*/
/*.sidebar-minimized .sidebar .navbar .dropdown-toggle:hover,*/
/*.navbar .sidebar-minimized .sidebar .dropdown-toggle:hover {*/
  /*width: 270px;*/
  /*background: #22262e;*/
/*}*/

/*.sidebar-minimized .sidebar .nav-link:hover .badge,*/
/*.sidebar-minimized .sidebar .navbar .dropdown-toggle:hover .badge,*/
/*.navbar .sidebar-minimized .sidebar .dropdown-toggle:hover .badge {*/
  /*display: inline;*/
/*}*/

/*.sidebar-minimized .sidebar .nav-link.nav-dropdown-toggle::before,*/
/*.sidebar-minimized .sidebar .navbar .nav-dropdown-toggle.dropdown-toggle::before,*/
/*.navbar .sidebar-minimized .sidebar .nav-dropdown-toggle.dropdown-toggle::before {*/
  /*display: none;*/
/*}*/

/*.sidebar-minimized .sidebar .nav-dropdown-items .nav-item {*/
  /*width: 220px;*/
/*}*/

/*.sidebar-minimized .sidebar .nav-dropdown-items .nav-item .nav-link,*/
/*.sidebar-minimized .sidebar .nav-dropdown-items .nav-item .navbar .dropdown-toggle,*/
/*.navbar .sidebar-minimized .sidebar .nav-dropdown-items .nav-item .dropdown-toggle {*/
  /*width: 220px;*/
/*}*/

/*.sidebar-minimized .sidebar .nav>.nav-dropdown>.nav-dropdown-items {*/
  /*display: none;*/
  /*max-height: 1000px;*/
  /*background: #383e4b;*/
/*}*/

/*.sidebar-minimized .sidebar .nav>.nav-dropdown:hover {*/
  /*background: #22262e;*/
/*}*/

/*.sidebar-minimized .sidebar .nav>.nav-dropdown:hover>.nav-dropdown-items {*/
  /*position: absolute;*/
  /*left: 50px;*/
  /*display: inline;*/
/*}*/

/*!*copy*!*/

/*@media (max-width: 991.98px) {*/
  /*.sidebar-mobile-show .main {*/
    /*margin-left: 70px !important;*/
  /*}*/

  /*.app-header.navbar .navbar-brand {*/
    /*position: initial;*/
    /*margin-left: -240px;*/
  /*}*/
/*}*/

dl dt {
  font-weight: normal;
}

.lh2 {
  line-height: 2rem;
}

.lh1 {
  line-height: 1.5rem;
}

.date-input {
  float: left;
  width: 45%;
  font-size: 0.5rem !important;
}

.date-to {
  float: left;
  width: 10%;
}

.tac {
  text-align: center;
}

.tar {
  text-align: right;
}

.card-block .search {
  padding: 0.5rem 2rem;
}

.status {
  display: block;
  padding: 0.3rem 0.5rem;
  background-color: #ccc;
}

/* .card-block .searching {
  padding: 0.5rem 2rem;
} */

.images-wrap {
  display: flex;
  flex-wrap: wrap;
}

.images-container {
  /*flex-grow: 1;*/
  height: 208px;
  margin: 0 5px 10px 0;
  position: relative;
  border: 4px solid transparent;
  border-radius: 6px;
  /*border: 10px solid rgba(192,192,192,.8);*/
}

.carousel-container {
  border-radius: 6px;
  background-color: #f0f2f7;
}

.carousel-container img {
  max-height: 300px;
}

.image-slide-box {
  border: 10px solid transparent;
  border-radius: 6px;
  /*width: 705px;*/
  background-color: transparent;
}

.image-slide-box img {
  max-width: 500px
}

/*.images-container img {*/

/*height: 200px;*/

/*object-fit: cover;*/

/*min-width: 300px;*/

/*max-width: 300px;*/

/*vertical-align: bottom;*/

/*border-radius: 6px;*/

/*}*/

.layers {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  height: 200px;
  width: 100%;
}

.images-container:hover {
  /*box-shadow: 0px 0px 12px #aaa;*/
  /*border:3px solid rgba(192,192,192,.8);*/
  border: 4px solid rgba(192, 192, 192, .3);
}

.images-container:hover .layers {
  display: block;
  background-color: rgba(0, 0, 0, .2);
}

/* File Uploader Styles  */

.uploader input {
  display: none;
}

.uploader {
  -webkit-align-items: center;
  align-items: center;
  background-color: #efefef;
  background-color: rgba(0, 0, 0, 0.02);
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  height: 250px;
  -webkit-justify-content: center;
  justify-content: center;
  outline: 3px dashed #ccc;
  outline-offset: 5px;
  position: relative;
}

.uploader img,
.uploader .icon {
  pointer-events: none;
}

.uploader,
.uploader .icon {
  -webkit-transition: all 100ms ease-in;
  -moz-transition: all 100ms ease-in;
  -ms-transition: all 100ms ease-in;
  -o-transition: all 100ms ease-in;
  transition: all 100ms ease-in;
}

.uploader .icon {
  color: #eee;
  color: rgba(0, 0, 0, 0.2);
  font-size: 5em;
}

.uploader img {
  left: 50%;
  opacity: 0;
  max-height: 100%;
  max-width: 100%;
  position: absolute;
  top: 50%;
  -webkit-transition: all 300ms ease-in;
  -moz-transition: all 300ms ease-in;
  -ms-transition: all 300ms ease-in;
  -o-transition: all 300ms ease-in;
  transition: all 300ms ease-in;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: -1;
}

.uploader img.loaded {
  opacity: 1;
}

.mmh-icon {
  display: block;
  margin-left: 10px;
}

.item-bars {
  background-color: #f0f2f7;
}

.bs-modal-title {
  font-family: PingFangSC-Regular;
  font-size: 18px;
  color: #212121;
  letter-spacing: 0;
}

.nav-item-font {
  font-family: PingFangSC-Regular;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 42px;
}

.facility-icon img {
  width: 34px;
  height: 34px;
}

.bottom-dashed {
  border-bottom: 1px dashed #c7c7c7;
}

.item-bars {
  background-color: #f0f2f7;
}

.nav-group-font {
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #FFFFFF;
  letter-spacing: 0;
}

.nav-item-font {
  display: inline-block;
  width: 125px;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
  color: #CCCCCC;
  vertical-align: middle;
}

.label-success {
  background-color: #00CB9D;
}

.label-agent {
  background-color: #00CB9D;
}

.label-wait {
  background-color: orange;
}

.label-invalid {
  background-color: red;
}

.label-owner {
  background-color: #20d1f9;
}

.text-label-success {
  color: #00CB9D;
}

.modal-default {
  background-color: #DDDDDD;
}

/*.modal-header {*/

/*height: 42px;*/

/*line-height: 42px;*/

/*padding: 0 15px;*/

/*-webkit-box-sizing: border-box;*/

/*-moz-box-sizing: border-box;*/

/*box-sizing: border-box;*/

/*background: #f5f5f5;*/

/*-webkit-border-radius: 3px 3px 0 0;*/

/*-moz-border-radius: 3px 3px 0 0;*/

/*border-radius: 3px 3px 0 0;*/

/*}*/

.modal-h4 {
  font-family: PingFangSC-Regular;
  font-size: 18px;
  color: #212121;
  letter-spacing: 0;
}

.ul-block {
  list-style-type: none;
  margin: 0px;
}

.box-flex-center {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
}

/*hotel info view page*/

.carousel .carousel-control {
  cursor: pointer;
}

.image-slide-box a img {
  cursor: pointer;
}

.carousel .carousel-control:hover {
  background-color: rgba(192, 192, 192, .8);
}

.modal-body .carousel-control:hover {
  background-color: rgba(192, 192, 192, .1);
}

/*.carousel-inner{height:331px;}*/

.carousel-inner .carousel-item .item {
  margin: 0 auto;
}

.showPic .modal-dialog .modal-content {
  background-color: transparent;
  border: none;
}

.showPic .modal-content .modal-header,
.showPic .modal-content .modal-footer {
  border: none;
}

.showPic .modal-header .close {
  color: #fff;
  font-size: 2rem;
}

.price {
  color: #1a7fb0;
  font-size: 1rem;
}

.price strong {
  font-size: 1.6rem;
}

.agent-info h6 {
  font-size: 1rem;
  color: #1a7fb0;
}

.agent-info p {
  color: #757575;
  font-size: 0.5rem;
}

.icon i,
.icon>span {
  vertical-align: middle;
}

.item-bars {
  height: 3rem;
}

.item-bars div {
  margin: 0 27px 0 20px;
  line-height: 3rem;
  font-size: 13px;
}

.property-info {
  padding: 0 15px;
}

.border-bottom {
  border-bottom: 1px solid #c7c7c7;
}

.map .container {
  padding: 0;
}

.map .map-details {
  padding: 0;
}

.login-topbar {
  width: 100%;
  position: absolute;
  top: 25px;
  right: 120px;
}

.ul-block {
  padding-left: 0px;
}

.mt5 {
  margin-top: 5px;
}

.mt8 {
  margin-top: 8px;
}

.mt10 {
  margin-top: 10px;
}

.mt15 {
  margin-top: 15px;
}

.mt20 {
  margin-top: 20px;
}

.mt25 {
  margin-top: 25px;
}

.mt30 {
  margin-top: 30px;
}

.mr5 {
  margin-right: 5px;
}

.mr8 {
  margin-right: 8px;
}

.mr20 {
  margin-right: 20px;
}

.mr48 {
  margin-right: 48px;
}

.pr20 {
  padding-right: 20px;
  box-sizing: border-box;
}

.mb0 {
  margin-bottom: 0px !important;
}

.mb2 {
  margin-bottom: 2px !important;
}

.mb5 {
  margin-bottom: 5px;
}

.mb10 {
  margin-bottom: 10px !important;
}
.is{
  font-weight: bold;
}
.my{
  color:#949494;
}
.i{
  float:left;
}

.mb20 {
  margin-bottom: 20px;
}

.mb30 {
  margin-bottom: 30px;
}

.ml5 {
  margin-left: 5px !important;
}

.pb30 {
  padding-bottom: 30px;
  box-sizing: border-box;
}

.pl31 {
  padding-left: 31px !important;
}

.pl35 {
  padding-left: 35px;
  box-sizing: border-box;
}

.fz18 {
  font-size: 18px !important;
}

.f16 {
  font-size: 16px !important;
}

.f12 {
  font-size: 12px !important;
}

.text-white {
  color: #fff !important;
}

.paddingl0 {
  padding-left: 0 !important;
}

.paddingr0 {
  padding-right: 0 !important;
}

.pt5 {
  padding-top: 5px;
}

.pt10 {
  padding-top: 10px;
}

.pb10 {
  padding-bottom: 10px;
}

.padding0 {
  padding: 0 !important;
}

.width66 {
  width: 66px;
}

.width88 {
  width: 88px;
}

.width118 {
  width: 118px;
}

.width140 {
  width: 140px;
}

.width580 {
  width: 580px !important;
}

.min-wt322 {
  min-width: 322px !important;
}

.terms-content label.col-md-2 {
  margin-right: 15px;
}

.radios-ul {
  padding: 0 !important;
  max-width: 68px !important;
}

.radios-ul .radios-ul-text,
.section-ul .section-ul-text {
  display: inline-block;
  height: 38px;
  line-height: 38px;
  width: 68px;
  text-align: center;
  font-size: 12px;
  color: #757575;
  background: #f5f5f5;
  box-sizing: border-box;
  border: 1px solid #f5f5f5;
  transition: all .2s;
  cursor: pointer;
}

.section-ul {
  padding: 0 !important;
  width: 132px !important;
  max-width: 132px !important;
  margin-right: 10px;
}

.section-ul .section-ul-text {
  width: 132px !important;
}

.section-ul-4 {
  float: left;
  width: 120px !important;
  max-width: 120px !important;
}

.section-ul-4 .section-ul-text {
  width: 120px !important;
}

.radios-ul input[type=radio],
.section-ul input[type=radio] {
  opacity: 0;
  display: none;
}

.radios-ul input[type=radio]:checked+.radios-ul-text,
.section-ul input[type=radio]:checked+.section-ul-text {
  color: #212121;
  background: rgba(80, 147, 225, .3);
  border-radius: 3px;
}

.facility-wrap input[type=checkbox] {
  margin-right: 8px;
}

header.navbar {
  height: 46px !important;
  background-color: #5093e1 !important;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.10);
  /*padding-right: 166px!important;*/
  box-sizing: border-box;
}

header.navbar .navbar-brand {
  height: 45px !important;
  background-color: #5093e1 !important;
  border-right: 1px solid rgba(255, 255, 255, .1);
  background-size: 120px auto !important;
}

.sidebar-minimized header.navbar .navbar-brand {
  background-size: 24px 27px !important;
}

.nav.navbar-nav.ml-auto .nav-item {
  margin-left: 9px !important;
}

.nav-link.navbar-toggler.sidebar-toggler {
  color: #ccc;
  cursor: pointer;
}

.header-fixed .app-body {
  margin-top: 45px !important;
}

.badge {
  font-weight: normal !important;
}

.badge-success {
  background: #00cb9d !important;
  font-weight: normal !important;
}

.sidebar .nav .nav-title {
  font-size: 16px !important;
  font-weight: normal !important;
  color: #fff !important;
  padding: 0.5rem 1.875rem !important;
}

.card-header .btn,
.card-header .fc button,
.fc .card-header button {
  margin-top: 0 !important;
}

/*.sidebar .nav .nav-item .nav-link, .sidebar .nav .nav-item .navbar .dropdown-toggle, .navbar .sidebar .nav .nav-item .dropdown-toggle {*/

/*padding: 0.2rem 1.925rem !important;*/

/*}*/

/*.sidebar .nav .nav-item .nav-link::before {*/

/*top: 0.36875rem !important;*/

/*}*/

/* .btn-primary, .btn-primary:active, .fc-today-button:active, .btn-primary.active, .active.fc-today-button, .show > .btn-primary.dropdown-toggle, .show > .dropdown-toggle.fc-today-button, .btn-info {
  color: #fff;
  background-color: #5093e1 !important;
  background-image: none;
  border-color: #5093e1 !important;
} */

/*.form-control, .daterangepicker .input-mini, .input-group > .ui-select-bootstrap > input.ui-select-search.form-control, .input-group > .ui-select-bootstrap > input.ui-select-search.form-control.direction-up {*/

/*color: #212121 !important;*/

/*}*/

/*.form-control:focus, .daterangepicker .input-mini:focus, .input-group > .ui-select-bootstrap > input.ui-select-search.form-control:focus {*/

/*border-color: #212121 !important;*/

/*}*/

.btn-link {
  color: #5093e1;
}

/*.text-info {*/
  /*color: #5093e1 !important;*/
/*}*/

/*.text-success {*/
  /*color: #00cb9d !important;*/
/*}*/

/*.text-warning {*/
  /*color: #f9a720 !important;*/
/*}*/

/*.btn-danger {*/
  /*background: #ff4033 !important;*/
/*}*/

/*.btn-danger:hover {*/
  /*background: #ff2121 !important;*/
/*}*/

/*.text-danger {*/
  /*color: #ff4033 !important;*/
/*}*/

/*.btn-secondary,*/
.fc button {
  color: #757575 !important;
}

.card {
  border-top-width: 1px !important;
}

.breadcrumb {
  margin: 0;
  padding: .5rem 1.5rem;
}

.breadcrumb li {
  display: inline;
}

.modal-dialog {
  margin-top: 130px !important;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  /* overflow: hidden; */
}

.modal-dialog.modal-top-sm {
  margin-top: 40px !important;
}

.modal-dialog .modal-content {
  border: none;
  box-shadow: 0 2px 19px 0 rgba(0, 0, 0, 0.10);
}

/*.modal-body {*/

/*padding: 15px 35px !important;*/

/*}*/

.foot-modal-btns {
  margin-bottom: 30px;
  justify-content: flex-end;
  display: flex;
}

.foot-modal-btns button {
  margin-right: 15px;
}

.select-tree-block .selected-item-text {
  font-size: 0.7rem !important;
}

.select-tree-block .selected-container-text {
  padding-top: 3px;
  padding-bottom: 3px;
}

.select-tree-block span {
  height: 11px;
  font-size: 0.7rem;
}

.plugin-select .ui-select-match span {
  font-size: 0.7rem !important;
  padding-top: 3px;
  padding-bottom: 2px;
}

.plugin-select .ui-select-search {
  font-size: 0.7rem !important;
  padding-top: 3px !important;
  padding-bottom: 2px !important;
}

.map-wrap {
  width: 100%;
  height: 340px;
  margin-top: 20px;
}

.map-location {
  float: left;
  transform: translate(-50%, -50%);
  position: absolute;
  left: 22%;
  top: 69%
}

/*样式紧凑*/

.sidebar .nav .nav-item ul li {
  min-height: 32px;
}

.sidebar .nav .nav-item ul li a {
  height: 100%;
}

.table .row {
  margin-left: 0px;
  margin-right: 0px;
}

.approve {
  font-size: 0.7rem !important;
}

.btn-s {
  font-size: 0.7rem !important;
}

.font-s {
  font-size: 0.7rem !important;
}

.font-smaller {
  font-size: 0.5rem !important;
}

.small {
  padding: 0.25rem 0.8rem !important;
}

.pr10 {
  padding-right: 10px !important;
}

.pr0 {
  padding-right: 0px !important;
}

.modal-dialog {
  font-size: 0.7rem !important;
}

.foot-modal-btns .btn {
  padding: 0.25rem 0.8rem !important;
  font-size: 0.7rem !important;
}

.dropdown-menu {
  font-size: 0.7rem !important;
}

.cancel {
  -webkit-appearance: none !important;
}

.btn-default {
  border-color: #e4e6eb !important;
}

.mydp .selection {
  background-color: transparent !important;
}

.form-login {
  width: 55%;
}

.login-right h2 {
  margin-bottom: 2rem;
  font-size: 2.5rem;
}

.login p {
  font-size: 0.9rem;
}

.valign {
  vertical-align: middle !important;
}

.valign-top {
  vertical-align: top !important;
}

.valign-sub {
  vertical-align: sub !important;
}

.valign-bottom {
  vertical-align: bottom !important;
}

.btn {
  cursor: pointer;
}

.btn-icon {
  margin-left: -2px;
  margin-right: 2px;
}

.fa {
  margin-right: 3px;
}

.btn-icon-r {
  margin-left: 2px;
  margin-right: 0px;
}

.btn-icon:before,
.btn-icon-r:before {
  padding: 0px !important;
}

.card-title {
  padding: 0.55rem 1.25rem !important;
}

.cursor {
  cursor: pointer;
}

/*.col-sm-6{text-align: left;}*/

/* @media (max-width: 1248px) and (min-width: 576px) {
  .col-md-12 {
    text-align: left;
    width: 100% !important;
    max-width: 100% !important;
    flex: 0 0 100% !important;
  }
  .col-sm-12 {
    text-align: left;
  }
  .maxw95,
  .maxw100,
  .maxw110,
  .maxw120,
  .maxw130,
  .maxw140 {
    padding-left: 15px;
    box-sizing: border-box;
  }
} */

.break {
  word-break: break-all;
}

.wrap {
  word-wrap: break-word;
}

.textarea {
  width: 100%;
  resize: none;
}

.resize {
  resize: none;
}

.input-style {
  margin-bottom: 0;
  vertical-align: sub;
}

.bcw {
  background-color: #fff !important;
}

.pages ul li {
  cursor: pointer;
}

.inline {
  display: inline-block !important;
}

.bold {
  font-weight: bold !important;
}

.create-title {
  border-bottom: 2px solid #eaeaea;
  padding-bottom: 5px;
  color: #000;
  font-size: 16px;
}

.supplier-name {
  border-bottom: 1px solid #eaeaea;
  margin: auto 0 !important;
  height: 32px;
  margin-bottom: 10px !important;
}

.sub-header {
  border-bottom: 1px solid #000;
  color: black;
  font-size: 14px;
  font-weight: bold;
}

.input-item {
  margin: auto 0 !important;
  height: 32px;
  margin-bottom: 10px !important;
}

.font-red {
  color: #f00;
}

.font-blue {
  color: #5093e1;
}

.wp4 {
  width: 4% !important;
}

.wp18 {
  width: 18% !important;
}

.wp20 {
  width: 20% !important;
}

.wp25 {
  width: 25% !important;
}

.wp30 {
  width: 30% !important;
}

.wp33 {
  width: 33% !important;
}

.wp40 {
  width: 40% !important;
}

.wp48 {
  width: 48% !important;
}

.wp4 {
  width: 4% !important;
}

.wp7 {
  width: 7% !important;
}

.wp10 {
  width: 10% !important;
}

.wp14 {
  width: 14% !important;
}

.wp75 {
  width: 75% !important;
}

.payment {
  margin-bottom: 3px;
}

.payment input {
  margin-right: 3px;
}

/*adjust*/

a,
button {
  cursor: pointer;
}

.padding-plain {
  padding: 3px 12px !important;
}

.text-none {
  text-transform: none !important;
}

.flex170 {
  flex: 0 0 170px !important;
}

.email-app nav,
.email-app main {
  padding: 20px 15px !important;
  max-height: 520px;
  overflow-y: auto;
}

.clearfix:after {
  content: '';
  height: 0;
  display: block;
  visibility: hidden;
  clear: both;
}

@media (min-width: 1248px) {
  .minh520 {
    min-height: 520px;
  }

  .minw300 {
    min-width: 300px !important;
  }

  .minw500 {
    min-width: 500px !important;
  }

  .maxw50 {
    width: 50px !important;
    max-width: 50px !important;
    padding-right: 0 !important;
  }

  .maxw60 {
    width: 60px !important;
    max-width: 60px !important;
    padding-right: 0 !important;
  }

  .maxw65 {
    width: 65px !important;
    max-width: 65px !important;
    padding-right: 0 !important;
  }

  .maxw80 {
    width: 80px !important;
    max-width: 80px !important;
    padding-right: 0 !important;
  }

  .maxw100 {
    width: 102px !important;
    max-width: 102px !important;
    padding-right: 0 !important;
  }

  .maxw95 {
    width: 95px !important;
    max-width: 95px !important;
    padding-right: 0 !important;
  }

  .maxw110 {
    width: 110px !important;
    max-width: 110px !important;
    padding-right: 0 !important;
  }

  .maxw120 {
    width: 120px !important;
    max-width: 120px !important;
    padding-right: 0 !important;
  }

  .maxw130 {
    width: 130px !important;
    max-width: 130px !important;
    padding-right: 0 !important;
  }

  .maxw135 {
    width: 135px !important;
    max-width: 135px !important;
    padding-right: 0 !important;
  }

  .maxw140 {
    width: 140px !important;
    max-width: 140px !important;
    padding-right: 0 !important;
  }

  .maxw380 {
    width: 380px !important;
    max-width: 380px !important;
    padding-right: 0 !important;
  }

  .maxw280 {
    width: 280px !important;
    max-width: 280px !important;
  }

  .maxw200 {
    width: 200px !important;
    max-width: 200px !important;
  }


  .width210 {
    width: 210px !important;
  }

  .pos-r {
    position: relative;
  }

  .pos-rb0 {
    position: absolute !important;
    top: 0;
    right: 0;
  }

  .padding-tb35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }

  .email-app-chart {
    width: 200px;
    background: #fff;
    left: 0;
    padding: 0 !important;
  }

  /*.email-app-chart nav{border:1px solid #e4e6eb; border-left: none;}*/
  .chart-right {
    padding-left: 225px;
    padding-right: 20px;
  }
}

@media (min-width: 1258px) {
  .print-chart {
    width: 59vw;
    overflow-x: auto;
  }
}

@media (min-width: 1440px) {
  .print-chart {
    width: 63.25vw;
    overflow-x: auto;
  }
}

@media (min-width: 1660px) {
  .print-chart {
    width: 67.85vw;
    overflow-x: auto;
  }
}

@media (min-width: 1860px) {
  .print-chart {
    width: 72.4vw;
    overflow-x: auto;
  }
}

.print-chart th,
.print-chart td {
  white-space: nowrap;
}

.aside-small-nav {
  border-bottom: 1px solid #e6e4eb;
}

.upload input {
  vertical-align: bottom;
  margin-right: 3px;
}

.h-auto {
  height: auto;
}

.category nav>div {
  width: 100%;
}

.category ul {
  margin: 0;
  padding: 1px;
  background: #f0f2f7;
}

.category ul li {
  list-style: none;
  margin-bottom: 1px;
  padding: 1rem;
  background: #FFFFFF;
}

/*.category ul li:hover{background-color: #f0f2f7;}*/

.category ul li a {
  font-size: 12px;
  padding: 0.1rem 0.2rem;
}

.image-list li {
  float: left;
  list-style: none;
  margin-right: 15px;
  margin-bottom: 15px;
  transition: all .3s
}

.image-list li:hover .set {
  display: block;
}

.set {
  width: 100%;
  height: 30px;
  line-height: 30px;
  color: #fff;
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: rgba(192, 192, 192, .6);
  text-align: center;
  display: none
}

.img-cover {
  width: 100%;
  height: 100%;
  color: #fff;
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: rgba(192, 192, 192, .6);
  text-align: center;
}

.cover {
  padding-left: 3px;
  padding-right: 3px;
  padding-top: 3px;
  height: 30px;
  width: 100%;
  line-height: 30px;
  color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  text-align: center;
}

.cover .cover-img {
  width: 20%;
  background-color: #5093E1;
}

.cover .status-img {
  width: 85px;
  background-color: #FF4033;
}

.delete {
  width: 20px;
  height: 20px;
  line-height: 20px;
  color: #FFFFFF;
  position: absolute;
  right: 3px;
  top: 3px;
  background: #2A8DFF;
;
  text-align: right;
  display: inline-block;
}

.verified {
  width: 20px;
  height: 20px;
  line-height: 20px;
  color: darkblue;
  position: absolute;
  right: 3px;
  top: 127px;
  background-color: white;
  text-align: right;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.block {
  display: block;
}

.upload-img {
  width: 200px;
  height: 150px;
  display: inline-block;
  border: 1px solid #ccc;
  float: left;
}

.upload-btn {
  width: 200px;
  height: 150px;
  top: 0;
  left: 0;
  opacity: 0;
}

.img-title {
  border-bottom: 1px solid #000;
  padding-bottom: 10px;
  margin-bottom: 40px;
}

.active-label {
  color: #5093E1;
}

baidu-map {
  width: 500px;
  height: 400px;
  display: block;
}

.all-in-map-page {
  height: 1080px;
}

#all-in-map {
  width: 100%;
  height: 100%;
}

.scroll-load-block {
  height: 100px;
  overflow-y: scroll;
  border: 1px solid #e4e6eb;
  border-radius: 2px;
}

.circle-tips {
  width: 12px;
  height: 12px;
  line-height: 12px;
  display: inline-block;
  border-radius: 10px;
  font-style: normal;
  font-size: 0.7rem;
  color: #fff;
  text-align: center;
  background-color: #f00;
}

.my-modal-date-picker .mydp .selector {
  position: absolute;
}

.mydp .btnclear,
.mydp .btnpicker {
  width: 16px !important;
}

.visible {
  overflow: visible !important;
}

.mini-carousel {
  height: 300px;
}

.house-details {
  border: 1px solid #ccc;
  margin-top: 10px;
  padding: 8px;
}

.property-name {
  font-size: 18px;
  border-bottom: 1px solid #ccc;
}

.vab {
  vertical-align: baseline;
}

.pay-all {
  background-color: #ebaa7d;
  color: #fff;
  font-size: 16px;
  line-height: 35px;
  padding-bottom: 5px;
  padding-top: 5px;
}

.deposit {
  font-size: 14px;
  border: 1px solid #ccc;
  background-color: #e9e9e9;
  padding-top: 5px;
  padding-bottom: 5px;
}

.pay-offline {
  border: 1px solid #ccc;
  font-size: 14px;
  padding-bottom: 5px;
  padding-top: 5px;
}

.fees {
  border: 1px dashed #ccc;
  width: 100%;
  padding-bottom: 10px;
}

.fees b {
  font-weight: normal;
}

.daily-fees {
  font-size: 14px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
}

.property-des,
.faciAndServi,
.location {
  font-size: 18px;
  border-bottom: 1px solid #ccc;
  height: 35px;
}

.des-content {
  text-indent: 2em;
}

.maps {
  width: 100%;
  height: 400px;
  border: 1px solid #ccc;
}

.basic-info {
  border-right: 1px dashed #ccc;
}

.wp100 {
  width: 100%;
}

.dash-b {
  border-bottom: 1px dashed #ccc;
}

.selected {
  color: #ff0000;
}

.cly {
  background-color: #fed926;
}

.clr {
  background-color: #ffcece;
}

.contract {
  padding-left: 16px;
}

.contract .first-line {
  text-indent: 2em;
}

.contract .underline {
  text-decoration: underline;
}

.contract tr td {
  text-align: left;
}

.con-upload input {
  width: 60px;
  height: 24px;
  font-size: 0 !important;
  z-index: 100;
  opacity: 0;
  top: 0;
  left: 0;
  z-index: 100;
}

.con-upload a {
  width: 80px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  border: 1px solid #ccc;
  top: 0;
  left: 0;
}

.con-filename {
  height: 24px;
  line-height: 24px;
  margin-left: 50px;
}

.bed-wrap {
  margin-top: -12px;
  padding-bottom: 15px;
}

.beds {
  width: 25px;
  height: 15px;
  line-height: 14px;
  text-align: center;
  vertical-align: middle;
  display: inline-block;
  border: 1px solid #ccc;
}

.text-old-data {
  text-decoration-line: line-through;
}

.refresh select {
  width: 32px;
  background-position: 15px !important;
  font-size: 14px;
  padding-left: 2px;
  color: #f00;
  margin: 0 5px;
}

.clb {
  color: #36a9e1;
}

.edit-progress {
  background: #F0F0F0;
  border: 1px solid #DBDBDB;
  box-shadow: 0 1px 2px 0 #D6D6D6;
}

.border-r {
  border-right: 1px solid #DBDBDB;
}

.border-b {
  border-bottom: 1px solid #DBDBDB;
}

.active-item {
  background: #FFFFFF !important;
}

.required-icon-before::before {
  content: '*';
  color: red;
}

.required-icon-after::after {
  content: '*';
  color: red;
}

.bg-main {
  background: #f0f2f7;
}

.hidden-input {
  border: none;
  width: 100%;
}

.hidden-input:focus {
  outline: none;
}

.delete label {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.delete label:after {
  content: '';
  width: 7.5px;
  height: 4px;
  position: absolute;
  top: 5px;
  left: 3px;
  z-index: 21;
  border: 1px solid #fff;
  border-top: none;
  border-right: none;
  background: transparent;
  opacity: 0;
  filter: alpha(opacity=0);
  transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
}

.delete input[type=checkbox] {
  margin: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  right: 0px;
  top: 0px;
}

.delete input[type=checkbox]:checked+label:after {
  opacity: 1;
  filter: alpha(opacity=100);
}

.card-header-progress {
  padding-top: 0.75rem;
  padding-right: 1.25rem;
  padding-bottom: 0.75rem;
  padding-left: 1.25rem;
  margin-bottom: 0;
  border-bottom: 1px solid #e4e6eb;
}

a.lightbox img {
  height: 150px;
  max-width: 200px;
  border: 3px solid white;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, .3);
  margin: 0px;
}

.img-active {
  border: 3px solid #2A8DFF !important;
}

.lightbox-target {
  padding-top: 100px;
  top: -100%;
  width: 100%;
  height: 100%;
  -webkit-transition: opacity .5s ease-in-out;
  -moz-transition: opacity .5s ease-in-out;
  -o-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out;
  overflow: hidden;
}

.lightbox-target img {
  max-height: 100%;
  max-width: 100%;
  border: 3px solid white;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, .3);
  box-sizing: border-box;
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
}

.img-content {
  height: 1248px;
}

.img-bg {
  background: rgba(0, 0, 0, .7) !important;
}

.bd0 {
  border: 0px !important;
}

/*.tooltip-inner {*/

/*max-width: 100% !important;*/

/*}*/

.has_error {
  border: 1px solid red;
}

/* 隐藏progress */

#loading-bar-spinner {
  display: none !important;
}

.main {
  background-color: #f2f2f2;
}

/*.sidebar-fixed .sidebar {*/
  /*!*height: calc(100vh - 50px); *!*/
  /*height: calc(100vh - 45px);*/
/*}*/

/*.sidebar .nav-dropdown-items {*/
  /*display: none;*/
/*}*/

/*.sidebar .nav-link.active,*/
/*.sidebar .navbar .active.dropdown-toggle,*/
/*.navbar .sidebar .active.dropdown-toggle {*/
  /*color: #36a9e1;*/
/*}*/

/*!*@media (min-width: 992px) {*!*/

/*!*.brand-minimized .app-header.navbar .navbar-brand {*!*/
/*!*width: 70px;*!*/
/*!*}*!*/

/*.sidebar-minimized .sidebar .nav {*/
  /*width: 70px;*/
/*}*/

/*.sidebar-minimized .sidebar .nav-item {*/
  /*width: 70px;*/
/*}*/

/*.sidebar-minimized.sidebar-hidden .sidebar {*/
  /*margin-left: -70px;*/
/*}*/

/*.navbar .sidebar-minimized .sidebar .dropdown-toggle i,*/
/*.sidebar-minimized .sidebar .nav-link i,*/
/*.sidebar-minimized .sidebar .navbar .dropdown-toggle i {*/
  /*width: 70px;*/
/*}*/

/*.sidebar-minimized.sidebar-fixed .main,*/
/*.sidebar-minimized.sidebar-fixed .app-footer {*/
  /*margin-left: 70px;*/
/*}*/

/*.sidebar-minimized.sidebar-fixed .sidebar {*/
  /*width: 70px;*/
/*}*/

/*.sidebar-minimized .sidebar .nav-link,*/
/*.sidebar-minimized .sidebar .navbar .dropdown-toggle,*/
/*.navbar .sidebar-minimized .sidebar .dropdown-toggle {*/
  /*padding: 10px 0 25px;*/
/*}*/

/*.sidebar-minimized .sidebar .nav-link:hover,*/
/*.sidebar-minimized .sidebar .navbar .dropdown-toggle:hover,*/
/*.navbar .sidebar-minimized .sidebar .dropdown-toggle:hover {*/
  /*width: inherit;*/
/*}*/

/*.sidebar-minimized .sidebar .nav .nav-item .nav-link span {*/
  /*position: absolute;*/
  /*bottom: 10px;*/
  /*left: 0;*/
  /*width: 70px;*/
  /*text-align: center;*/
  /*z-index: 110;*/
/*}*/

/*.sidebar-minimized .sidebar .nav .nav-dropdown>.nav-dropdown-items {*/
  /*max-height: 1000px;*/
/*}*/

/*.sidebar-minimized .sidebar .nav .nav-dropdown:hover>.nav-dropdown-items {*/
  /*position: absolute;*/
  /*left: 70px;*/
  /*display: inline;*/
  /*top: 0;*/
  /*z-index: 100;*/
/*}*/

/*.sidebar .nav-dropdown.open {*/
  /*background: #383e4b;*/
/*}*/

/*}*/

.gw.sidebar .open .nav-dropdown-items {
  display: block;
}

.gw li.nav-item.nav-dropdown {
  height: 50px;
}

.gw .nav-item.nav-dropdown .open {
  height: 100%;
}

/* .sidebar .nav-link.active,
.sidebar .navbar .active.dropdown-toggle,
.navbar .sidebar .active.dropdown-toggle {
    color: #fff;
    background: #22262e;
} */

/* NZ-DATEPICKER */

.cdk-overlay-container {
  z-index: 1100 !important;
}

.open>.dropdown-menu {
  display: block;
}

.circular {
  border-radius: 50%;
}

.span-gray {
  color: gray;
}

.span-blue {
  color: blue;
}

button {
  cursor: pointer;
}

span.badge a,
button a,
a.btn.btn-warning {
  color: #fff;
}

.btn-secondary,
.fc button {
  color: #383e4b;
  background-color: #fff;
  border-color: #ccc;
}

* {
  font-size: 14px;
}

a.nav-link.nav-dropdown-toggle>i {
  vertical-align: middle;
  display: inline-block;
}

input[type="text"],
input[type="number"]
{
  width: 143px;
  height: 32px;
  line-height: 32px;
  border-radius: 4px;
  display: inline-block;
  border: 1px solid #e4e6eb;
  padding: 0 5px;
}

input[type="text"].input-lg {
  width: 243px;
}

input.form-control {
  width: 100%;
}

table th,
table td {
  text-align: center;
  vertical-align: middle !important;
  height: 2rem;
  line-height: 2rem;
  display: table-cell;
  padding: 0;
}

.td_tmp {
  text-align: left;
  vertical-align: middle !important;
  height: 2rem;
  line-height: 2rem;
  display: table-cell;
  padding: 0;
}

button {
  border: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

button:focus {
  outline: none;
  box-shadow: none;
}

.badge-success {
  background: #00cb9d !important;
  font-weight: normal !important;
}

.btn-green {
  background: #00cb9d;
  color: #fff;
  border: 1px solid #09b58e;
}

.btn-green:hover {
  background: #09b58e;
  color: #fff;
  border: 1px solid #09b58e;
}

.btn-red {
  background: #ff5454;
  color: #fff;
  border: 1px solid #e63030;
}

.btn-red:hover {
  background: #e63030;
  color: #fff;
  border: 1px solid #e63030;
}

.btn-blue {
  color: #fff;
  background-color: #36a9e1;
  border-color: #229cd8;
}

.btn-blue:hover {
  background: #229cd8;
  color: #fff;
  border-color: #229cd8;
}

.btn-yellow {
  color: #fff;
  background-color: #fabb3d;
  border-color: #d49b29;
}

.btn-yellow:hover {
  background: #d49b29;
  color: #fff;
  border-color: #d49b29;
}

.btn-gray {
  color: #fff;
  background-color: #666;
  border-color: #6c757d;
}

.btn-gray:hover {
  background: #484d52;
  color: #fff;
  border-color: #6c757d;
}

button>a:hover {
  color: #fff;
}

.btn-link>a {
  color: #36a9e1;
}

.btn-link>a:hover {
  color: #229cd8;
}

.btn-secondary,
.btn-secondary>a {
  color: #383e4b;
  border-color: #ccc;
}

.btn-secondary:hover,
.btn-secondary>a:hover {
  color: #333;
}

.hei32 {
  height: 32px;
  line-height: 32px;
}

.hei2532 {
  height: 25px;
  line-height: 32px;
}

/*button.btn.btn-warning {*/
  /*color: #fff;*/
/*}*/

li.nav-item.dropdown {
  padding: 0.2rem 0.5rem;
}

ul.nav.navbar-nav.ml-auto>div>span {
  padding: 0 1rem;
  color: #fff;
  letter-spacing: 2px;
}

a.fa.fa-sign-out {
  /* background: #1895d2; */
  padding: 0.5rem;
  color: #fff;
}

.red {
  color: red;
}

button.btn.btn-ghost {
  background: #fff;
  border: 1px solid #36a9e1;
  color: #36a9e1;
  margin-left: 5px;
  font-size: 14px;
  height: 32px;
}

button.btn.btn-ghost:hover {
  background: #36a9e1;
  color: #fff;
}

button.btn.btn-g-blue {
  /*background: #fff;*/
  border: 1px solid #36a9e1;
  /*color: #36a9e1;*/
  margin-left: 5px;
  font-size: 14px;
  height: 32px;
}

/* button.btn.btn-secondary {
  font-size: 14px;
  height: 32px;
} */

.modal-footer {
  justify-content: center;
}

.mar10 {
  margin-bottom: 10px;
}

.list-top ul li.blue-bor {
  border-bottom: 2px solid #07a4f1;
  color: #07a4f1;
}

.list-top ul li {
  float: left;
  padding: 15px 20px;
  text-align: left;
  color: #000;
  font-weight: bold;
  position: relative;
  cursor: pointer;
  margin-top: 15px;
}

.list-top ul li:hover {
  border-bottom: 2px solid #07a4f1;
  color: #07a4f1;
}

.list-top ul {
  width: 100%;
}

.list-top {
  border-bottom: 1px solid #ccc;
  overflow: hidden;
  margin-bottom: 15px;
}

.list-tt {
  width: 96%;
  overflow: hidden;
  margin: auto;
  padding: 0 0 14px;
  border-bottom: 1px solid #ccc;
}

/* table tr td:last-child{
    text-align: left!important;
} */

.modal .modal-title {
  margin-bottom: 0;
  line-height: 1.5;
  color: #fff !important;
}

.modal .modal-footer {
  justify-content: flex-end !important;
}

/*.modal-danger .modal-header,*/
/*.modal-header {*/
/*color: #fff !important;*/
/*background-color: #36a9e1 !important;*/
/*}*/

.modal-header>h4.modal-title {
  color: #fff;
}

select {
  height: 32px;
  line-height: 32px;
  border: 1px solid #e4e6eb;
  border-radius: 4px;
}

.input-group>.form-control:not(:last-child),
.input-group>.custom-select:not(:last-child) {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

li.nav-item.nav-dropdown {
  height: 70px;
}

a.nav-link.nav-dropdown-toggle.nav-menu {
  height: 70px;
  padding: 15px 0 10px;
}

li.nav-item.item-menu {
  height: 70px;
}

a.fa.fa-sign-out:hover {
  color: #e6e6e6;
}

.main {
  position: relative;
}

.new-line {
  word-break: break-all;
  word-wrap: break-word;
  width: 20%;
}

button.close span {
  font-size: 20px;
}

.table thead th,
.table tbody td {
  border: 1px solid #ccc;
}

.btn-green:not(:disabled):not(.disabled):active,
.btn-green:not(:disabled):not(.disabled).active,
.show>.btn-green.dropdown-toggle {
  color: #fff;
  background-color: #09b58e;
  border-color: #09b58e;
}

.btn-green:focus,
.btn-green.focus,
.btn-green:not(:disabled):not(.disabled):active:focus,
.btn-green:not(:disabled):not(.disabled).active:focus,
.show>.btn-green.dropdown-toggle:focus {
  box-shadow: none;
}

.w80 {
  width: 80% !important;
}

.upfile .button-file {
  clear: both;
  display: block;
  width: 100%;
  text-align: center;
}

.upfile label.img-ul-upload.img-ul-button.img-file {
  display: flex;
  margin: 40px auto 0;
  width: 180px;
  height: 82px;
  float: inherit;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  box-shadow: 0 5px 14px #999;
  background: #33CC99;
}

.upfile .img-ul {
  height: 214px;
  margin-bottom: 10px;
}

.btn-outline-info:hover {
  color: #fff;
}

.dropdown-menu {
  margin: 0;
}

li.foot-li {
  width: 100%;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
}

li.foot-li>app-module-checkbox {
  width: 33.33%;
  padding-bottom: 30px;
}

.parts1 .parts2 {
  padding-left: 2rem;
}

.new-lines {
  word-break: break-all;
  word-wrap: break-word;
}

my-date-picker {
  width: 100%;
}

.app-header.navbar .navbar-nav {
  height: 100%;
}

li.nav-item.dropdown.backlog {
  background: #fff;
  height: 100%;
  border-bottom: 10px solid #5093e1;
}

.triangle-up {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 0;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  border-bottom: 10px solid #5093e1;
}

a.nav-link.dropdown-toggle.backlog-a {
  color: #5093e1;
  font-weight: bolder;
  position: relative;
  height: 100%;
}

.red-tips {
  width: 20px;
  height: 20px;
  background: red;
  border-radius: 100%;
  display: block;
  position: relative;
  top: -11px;
  right: -18px;
  font-style: normal;
  padding: 0 2px;
  color: #fff;
}

a.nav-link.dropdown-toggle.backlog-a::after {
  content: initial;
}

a.nav-link.dropdown-toggle.backlog-a span.d-md-down-none {
  font-size: 15px;
}

.d-md-down-none {
  /*padding: 0 1rem;*/
  /*color: #fff;*/
  /*letter-spacing: 2px;*/
}

a.nav-link.dropdown-toggle.backlog-a:hover,
li.nav-item.dropdown.backlog:hover a {
  color: #0988c5 !important;
}

.dropdown-menu.big-dropdown {
  width: 316px;
  height: 107px;
  box-shadow: 1px 4px 8px #999;
  top: 113%;
  border-radius: 0;
}

.dropdown-menu.big-dropdown ol {
  display: flex;
  width: 260px;
  margin: 20px auto;
}

.dropdown-menu.big-dropdown span {
  width: 100%;
  display: block;
  color: #686868;
}

.dropdown-menu.big-dropdown ol span.span-top.color-red {
  color: red;
}

.dropdown-menu.big-dropdown ol span.span-top {
  font-size: 20px !important;
  font-weight: bold;
}

.dropdown-menu.big-dropdown ol li {
  width: 65px;
  text-align: center;
  border-left: 1px solid #ccc;
}

span.span-buttom {
  font-size: 17px !important;
}

.new-row {
  word-break: break-all;
  word-wrap: break-word;
}

.ant-modal-content>.ant-modal-header {
  background: #36a9e1;
  font-size: 17px;
}

div#nzModal1,
.ant-modal-close-x {
  color: #fff;
}

button.ant-btn.ant-btn-primary.ant-btn-lg {
  background-color: #36a9e1;
  border-color: #36a9e1;
}

button.ant-btn.ant-btn-primary.ant-btn-lg:hover {
  color: #fff;
  background-color: #1f97d1;
  border-color: #1e8fc6;
}

.ant-modal-header>.ant-modal-title {
  color: #fff;
}

.modal-xl {
  max-width: 1000px;
}

.modal-2xl {
  max-width: 1300px;
}

/*.btn-info {*/
  /*color: #fff;*/
  /*background-color: #79b7fd !important;*/
  /*border-color: #3484e2 !important;*/
/*}*/

/*.btn-info:hover {*/
  /*color: #fff;*/
/*}*/

.text-sm {
  font-size: 0.8rem;
}

/*mydp*/

.mydp,
.mydp .selectiongroup {
  height: 32px;
}

input.selection.ng-untouched.ng-pristine.ng-valid {
  height: 32px !important;
  padding: 0;
}

.mydp .selectiongroup {
  border: 1px solid #e4e6eb !important;

}

.mydp {
  border: 0 !important;
}

.mydp .selbtngroup {
  height: 32px !important;
  padding-right: 3px;
}

/*!*tree*!*/
/*.ant-tree.ant-tree-show-line>nz-tree-node:not(:last-child)>li:before,*/
/*.ant-tree.ant-tree-show-line .ant-tree-child-tree>nz-tree-node:not(:last-child)>li:before {*/
/*  display: none;*/
/*}*/

/*.ant-tree li {*/
/*  padding: 0 !important;*/
/*  margin: 0;*/
/*  list-style: none;*/
/*  white-space: nowrap;*/
/*  outline: 0;*/
/*  !* border: 1px solid #DFDFDF; *!*/
/*  border-radius: 0px;*/
/*  margin-top: -1px;*/
/*}*/

/*.ant-tree li ul {*/
/*  padding: 0 !important;*/
/*}*/

/*nz-tree-node.ng-star-inserted>li {*/
/*  !* border: 1px solid #ccc; *!*/
/*  margin-top: -1px;*/
/*  border-bottom: 0;*/
/*}*/

/*.tree {*/
/*  border: 1px solid #dfdfdf;*/
/*  border-radius: 4px;*/
/*  background: url(../../assets/img/line.png) repeat top left;*/
/*  background-size: 10px 47px;*/
/*}*/

/*nz-tree-node.ng-star-inserted>li {*/
/*  border-left: 0;*/
/*  border-right: 0;*/
/*  border-bottom: 0;*/
/*}*/

/*.ant-tree li span.ant-tree-switcher,*/
/*.ant-tree li span.ant-tree-iconEle,*/
/*.ant-tree li span,*/
/*nz-tree-node.ng-star-inserted span {*/
/*  height: 48px !important;*/
/*  line-height: 48px !important;*/
/*}*/

/*nz-tree-node.ng-star-inserted li {*/
/*  padding-left: 20px !important;*/
/*}*/

/*.ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected,*/
/*.ant-tree li .ant-tree-node-content-wrapper:hover {*/
/*  background-color: transparent !important;*/
/*}*/

/*.ant-tree li span.ant-tree-switcher,*/
/*.ant-tree li span.ant-tree-iconEle {*/
/*  border-top: 1px solid #dfdfdf !important;*/
/*  border-bottom: 1px solid #dfdfdf !important;*/
/*}*/

/*.treeview-header,*/
/*.row.row-all {*/
/*  display: none !important;*/
/*}*/

/*.treeview-container {*/
/*  max-height: inherit !important;*/
/*}*/

/*.treeview-text {*/
/*  display: none;*/
/*  padding: 0;*/
/*}*/

/*.select-box .dropdown-header,*/
/*.item .dropdown-header {*/
/*  padding: 8px 5px;*/
/*  background: #fff;*/
/*  border-bottom: 0;*/
/*  color: #333;*/
/*  font-size: 16px;*/
/*  font-weight: bold;*/
/*}*/

/*.select-box a.ngx-select__item.dropdown-item,*/
/*.item a.ngx-select__item.dropdown-item {*/
/*  border: 0;*/
/*}*/

/*.select-box .ngx-select_multiple .ngx-select__search,*/
/*.select-box .ngx-select.dropdown.ngx-select_multiple.form-control,*/
/*.item .ngx-select_multiple .ngx-select__search,*/
/*.item .ngx-select.dropdown.ngx-select_multiple.form-control {*/
/*  !* height: 32px !important;*/
/*  line-height: 32px; *!*/
/*  padding: 0 5px !important;*/
/*  margin: 0;*/
/*}*/

/*.select-box .ngx-select__choices {*/
/*  max-height: 400px;*/
/*}*/

/*.select-box .dropdown-divider {*/
/*  margin: 0;*/
/*}*/

/*!* .item .ngx-select__choices {*/
/*  max-height: inherit !important;*/
/*} *!*/

/*.ngx-select__toggle.btn.form-control {*/
/*  height: 32px;*/
/*}*/

.mydp,
.mydp .headertodaybtn {
  /* border: 1px solid #e4e6eb !important; */
  height: 32px;
  /* line-height: 32px !important; */
}

.setbox .tab-content .tab-pane {
  padding: 15px 0;
}

.mydp .selectiongroup {
  border-bottom: 1px solid #e4e6eb !important;
  height: 26px;
  /* line-height: 26px; */
}

.ngx-select__choices.dropdown-menu {
  overflow-y: auto;
}

.select-box .ngx-select_multiple .ngx-select__search {
  height: 30px !important;
  line-height: 30px;
  margin-bottom: 0 !important;
}

/* bugfix 自动消失长度少点不然露出一截*/
/*v2 delete*/
/*@media (max-width: 768px) {*/
  /*.sidebar-minimized .sidebar .nav-dropdown-items .nav-item {*/
    /*width: 150px;*/
  /*}*/

  /*.sidebar-minimized .sidebar .nav-item:hover {*/
    /*width: 150px;*/
  /*}*/

  /*.sidebar-minimized .sidebar .nav-dropdown-items .nav-item .nav-link,*/
  /*.sidebar-minimized .sidebar .nav-dropdown-items .nav-item .navbar .dropdown-toggle,*/
  /*.navbar .sidebar-minimized .sidebar .nav-dropdown-items .nav-item .dropdown-toggle {*/
    /*width: 150px;*/
  /*}*/
/*}*/

/*fixbug*/
.mydp input.selection {
  padding-left: 6px !important;
}

.close {
  float: right;
  font-size: 1.3125rem;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-shadow: none;
  opacity: 1;
}

.close:hover {
  color: #fff;
  text-shadow: 0 1px 1px 0 #fff;
}

/* canlendar */

.room-p .cal-month-view .cal-day-cell {
  min-height: 50px;
}

.room-p .cal-month-view .cal-cell-top {
  height: 26px;
  min-height: 60px;
}

.room-p .cal-month-view .cal-day-badge {
  top: 3px;
  left: 5px;
  background-color: #3a302f3b;
  display: inline-block;
  min-width: 10px;
  padding: 8px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  color: white;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: 16px;
  float: left;
  position: relative;
}

.room-p .cal-month-view .cal-day-badge-none {
  top: 3px;
  left: 5px;
  background-color: inherit;
  display: inline-block;
  min-width: 10px;
  padding: 8px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  color: white;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: 16px;
  float: left;
  position: relative;
  opacity: 0;
}

.room-p .cal-month-view .cal-day-number {
  font-size: 1.2em;
  font-weight: 400;
  opacity: 0.5;
  margin-top: 5px;
  margin-right: 10px;
  float: right;
  bottom: 7px;
  position: relative;
  clear: both;
}

.room-p .cal-month-view {
  background: #fff;
}

.rotateY90 {
  animation: rotateY90 1s;
}

@keyframes rotateY90 {
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(90deg);
  }
  100% {
    transform: rotateY(0deg);
  }
}

.modal-header {
  color: #fff;
  background-color: #36a9e1;
}

#mainContainer .toolbar {
  z-index: 100 !important;
}

.dropdownToolbarButton > select {
  height: 20px;
}

.btn-back {
  position: absolute;
  top: 0;
  right: 10px;
}

.btn-back::after {
  clear: both;
}





@media (max-height: 600px){
  .sidebar-minimized.sidebar-fixed .sidebar {
    width: 70px;

    overflow-y: scroll;
  }


  .sidebar-minimized .sidebar .nav .nav-dropdown:hover>.nav-dropdown-items {
    position: fixed;
    top: 46px;
    z-index: 100;
    overflow: visible;
  }
}


.badge-green {
  color: #ffffff;
  background-color: #21c485;
}

.badge-green[href]:hover,
.badge-green[href]:focus {
  color: #ffffff;
  background-color: #21c485;
}


/**/
.visual-mobile.project-guide {
  line-height: 1.5;
}

.visual-mobile.project-guide p {
  margin-block-start: .5em;
  margin-block-end: .5em;
}

.visual-mobile.project-guide p,
.visual-mobile.project-guide span {
  font-size: 11px;
  color: initial;
}

.visual-mobile.project-guide h1 {
  font-size: 2.5rem;
  margin: 0;
}

.visual-mobile.project-guide h2 {
  font-size: 2rem;
  margin: 0;
}

.visual-mobile.project-guide h3 {
  font-size: 1.75rem;
  margin: 0;
}

.visual-mobile.project-guide h4 {
  font-size: 1.5rem;
  margin: 0;
}

.visual-mobile.project-guide h5 {
  font-size: 1.25rem;
  margin: 0;
}

.visual-mobile.project-guide h6 {
  font-size: 1rem;
  margin: 0;
}

.visual-mobile.project-guide b,
.visual-mobile.project-guide strong,
.visual-mobile.project-guide u,
.visual-mobile.project-guide s,
.visual-mobile.project-guide em,
.visual-mobile.project-guide span {
  font-size: inherit;
}

.ant-picker {
  padding: 0 2px;
  border-radius: 4px;
  border: 1px solid #e4e6eb;
}

.ant-picker input[type='text'] {
  border: none;
}
