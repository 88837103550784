@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?1pidcs');
  src:  url('../fonts/icomoon.eot?1pidcs#iefix') format('embedded-opentype'),
  url('../fonts/icomoon.ttf?1pidcs') format('truetype'),
  url('../fonts/icomoon.woff?1pidcs') format('woff'),
  url('../fonts/icomoon.svg?1pidcs#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icomoon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icomoon-iconku:before {
  content: "\e902";
  color: #fff;
  font-size: 23px;
}
.icomoon-queren:before {
  content: "\e903";
  color: #fff;
  font-size: 23px;
}
.icomoon-success:before {
  content: "\e904";
  color: #fff;
  font-size: 23px;
}
.icomoon-zhuye:before {
  content: "\e905";
  color: #fff;
  font-size: 23px;
}
.icomoon--2:before {
  content: "\e901";
  color: #f5ffb2;
}
.icomoon--1:before {
  content: "\e900";
  color: #d81e06;
}

.icomoon-wangguan:before {
  content: "\e906";
}

